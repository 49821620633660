import { useEffect } from 'react';
import { Flex, Spin } from 'antd';
import HeadingSection from './HeadingSection';
import IntroSection from './IntroSection';
import PostList from './PostList';
import useApiFetch from '../../../shared/hooks/useApiFetch';
import StartupService from '../../../api/services/profiles/startup.service';
import { GetStartupProfileParams, StartupProfileResponse } from '../../../api/services/profiles/profile.dto';
import PageTitle from '../../../shared/components/PageTitle';
import GeneralSection from './GeneralSection';
import HeadingSectionPrivate from './HeadingSectionPrivate';
import ErrorSection from '../../../shared/components/ErrorSection';
import { toast } from 'react-toastify';

interface Props {
  handle?: string;
}

const StartupProfile = ({ handle }: Props) => {
  // TODO Check user-store rather than route to determine permissions
  const canUpdate = !handle;
  const {
    data: startup,
    loading,
    error,
    status,
    refetch,
  } = useApiFetch<GetStartupProfileParams, StartupProfileResponse>(StartupService.getStartupProfile, {
    // The legacy api route asks for id, when it means handle
    id: handle,
    // The legacy api route requires role, can remove once the api is updated
    currentRole: 'startup',
  });

  useEffect(() => {
    if (status === 404) {
      toast.info('Click the edit icon to get started. Enter the company name to save your profile');
    }
  }, [status]);

  if (error && status !== 404) {
    return <ErrorSection error={error} />;
  }

  const isPrivateProfile = !canUpdate && startup?.isPrivateProfile;

  return (
    <Spin spinning={loading}>
      <PageTitle title={startup?.companyName} />

      <div id="section">
        {isPrivateProfile ? (
          <HeadingSectionPrivate
            profilePhoto={startup?.profilePhoto}
            coverPhoto={startup?.coverPhoto}
            companyName={startup?.companyName}
          />
        ) : (
          <Flex vertical gap={24} className="py-8 px-24">
            <HeadingSection
              canUpdate={canUpdate}
              profilePhoto={startup?.profilePhoto}
              coverPhoto={startup?.coverPhoto}
              companyName={startup?.companyName}
              handle={startup?.handle}
              country={startup?.country}
              elevatorPitch={startup?.companyProfile}
              numberOfFollowers={startup?.followersCount}
              isPrivateProfile={startup?.isPrivateProfile}
              isFollowing={startup?.isFollowing}
              id={startup?.id}
              refetch={refetch}
            />
            <GeneralSection data={startup} canUpdate={canUpdate} refetch={refetch} />
            <IntroSection
              aboutCompany={startup?.aboutCompany}
              interestCategories={startup?.interestCategories}
              canUpdate={canUpdate}
              refetch={refetch}
            />
            <PostList activities={startup?.newsFeed} canUpdate={canUpdate} refetch={refetch} />
          </Flex>
        )}
      </div>
    </Spin>
  );
};

export default StartupProfile;
