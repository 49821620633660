import { AuthResponse } from '@supabase/supabase-js';
import supabase from '../../../modules/auth/utils/supabase';
import APIClient from '../../client';
import {
  ChangePasswordDto,
  ForgotPasswordDto,
  LoginDto,
  OldLoginResponse,
  RegisterDto,
  RegisterResponse,
  SendVerificationEmailDto,
  UpdatePasswordDto,
  VerifyOTPDto,
} from './auth.dto';
import { AUTH_ROUTES } from './auth.routes';
import AUTH_PATHS from '../../../routes/auth.paths';

export default class AuthService {
  #emailRedirectTo;

  constructor() {
    if (!process.env.REACT_APP_BASE_URL) {
      throw new Error('Base url is not defined');
    }
    // Backend will add /:result
    this.#emailRedirectTo = `${process.env.REACT_APP_BASE_URL}${AUTH_PATHS.VERIFY_RESULT.replace('/:result', '')}`;
  }

  public async register(email: string, password: string): Promise<AuthResponse> {
    return await supabase.auth.signUp({ email, password, options: { emailRedirectTo: this.#emailRedirectTo } });
  }

  public async login(email: string, password: string): Promise<AuthResponse> {
    return await supabase.auth.signInWithPassword({ email, password });
  }
}

// TODO Old, remove
export const OldAuthService = {
  login: (data: LoginDto): Promise<OldLoginResponse> => APIClient.post({ route: AUTH_ROUTES.LOGIN, data, isPublic: true }),
  register: (data: RegisterDto): Promise<RegisterResponse> =>
    APIClient.post({ route: AUTH_ROUTES.REGISTER, data, isPublic: true }),
  sendVerificationEmail: (data: SendVerificationEmailDto) =>
    APIClient.post({ route: AUTH_ROUTES.SEND_VERIFICATION_EMAIL, data, isPublic: true }),
  forgotPassword: (data: ForgotPasswordDto) =>
    APIClient.post({ route: AUTH_ROUTES.FORGOT_PASSWORD, data, isPublic: true }),
  updatePassword: (data: UpdatePasswordDto) => APIClient.post({ route: AUTH_ROUTES.UPDATE_PASSWORD, data }),
  changePassword: (data: ChangePasswordDto) => APIClient.post({ route: AUTH_ROUTES.CHANGE_PASSWORD, data }),
  emailVerification: () => APIClient.post({ route: AUTH_ROUTES.VERIFY_EMAIL }),
  sendOTP: () => APIClient.post({ route: AUTH_ROUTES.SEND_OTP }),
  verifyOTP: (data: VerifyOTPDto) => APIClient.post({ route: AUTH_ROUTES.VERIFY_OTP, data }),
  otpStatus: () => APIClient.get({ route: AUTH_ROUTES.OTP_STATUS }),
};
