import LoginPage from '../views/auth/LoginPage';
import SignUpPage from '../views/auth/SignUpPage';
import ResetPassword from '../views/auth/resetpassword';
import ConfirmPassword from '../views/auth/confirmPassword';
import VerifyEmail from '../views/auth/verifyEmail';
import EmailVerification from '../views/auth/verification';
import { resetPassword, verifyEmail, sendEmail, updatePassword } from './paths';
import AUTH_PATHS from './auth.paths';

export const PublicRoutes = [
  {
    title: 'Signup',
    component: SignUpPage,
    path: AUTH_PATHS.SIGNUP,
  },
  {
    title: 'Login',
    component: LoginPage,
    path: AUTH_PATHS.LOGIN,
  },
  {
    title: 'Reset Password',
    component: ResetPassword,
    path: resetPassword,
  },
  {
    title: 'Verify Email',
    component: VerifyEmail,
    path: sendEmail,
  },
  {
    title: 'Email Verification',
    component: EmailVerification,
    path: verifyEmail,
  },
  {
    title: 'Update Password',
    component: ConfirmPassword,
    path: updatePassword,
  },
];
