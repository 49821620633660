import React from 'react';
import { Google, Facebook, Twitter, linkdin } from '../../../utils/images/index';
import { Button, Col, Grid, Row } from 'antd';
import { config } from '../../../config';

interface SocialLinkProps {
  src: string;
  alt: string;
  provider: string;
  name: string;
  disabled?: boolean;
}

// added ability to disable social links by using disabled prop
const SocialLink: React.FC<SocialLinkProps> = ({ src, alt, provider, name, disabled = false }) => {
  const { md } = Grid.useBreakpoint();

  const handleLogin = (): void => {
    window.open(`${config?.ApiBaseURL}/auth/${provider}`, '_self');
  };
  return (
    <Button
      disabled={disabled}
      size="large"
      type="default"
      onClick={handleLogin}
      className={`${md ? 'tw-justify-center' : 'tw-justify-start'}tw-flex tw-gap-2 tw-items-center tw-w-full tw-px-2 tw-py-3 tw-border-border-gray`}
    >
      <img width={32} height={32} className={disabled ? 'tw-opacity-50' : ''} src={src} alt={alt} />
      {md && (
        <span className={`tw-text-base ${disabled ? 'tw-text-text-disabled' : 'tw-text-text-primary'}`}>{name}</span>
      )}
    </Button>
  );
};

const SocialLinks: React.FC = () => {
  const socialProviders = [
    { src: Google, alt: 'google', provider: 'google', name: 'Google' },
    { src: Facebook, alt: 'facebook', provider: 'facebook', name: 'Facebook', disabled: true },
    { src: Twitter, alt: 'twitter', provider: 'twitter', name: 'Twitter', disabled: true },
    { src: linkdin, alt: 'linkedin', provider: 'linkedin', name: 'LinkedIn' },
  ];

  return (
    <Row gutter={[12, 12]}>
      {socialProviders.map(provider => (
        <Col key={provider.alt} span={6}>
          <SocialLink key={provider.alt} {...provider} />
        </Col>
      ))}
    </Row>
  );
};

export default SocialLinks;
