import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useUpdateStartupProfile } from '../hooks/useUpdateStartupProfile';
import IndustrySelect from '../../profile-setup/components/IndustrySelect';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const StartupIntroEditModal = ({ open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm();
  const { handleSubmit, loading } = useUpdateStartupProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit Introduction
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        size="small"
        onFinish={handleSubmit}
      >
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item name="aboutCompany" label="About company">
              <Input.TextArea
                placeholder="Highlight your mission, unique strengths, and the problem you're solving. Capture what makes your startup exciting and investable"
                autoSize={{ minRows: 6 }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="interestCategories"
              label="Industry"
            >
              <IndustrySelect placeholder="Select industry" mode="multiple" maxTagCount={2} allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default StartupIntroEditModal;
