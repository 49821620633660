import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Container, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import OldApi from '../api-old/api';
import PostContent from '../components/PostContent';
import PageTitle from '../components/PageTitle';

const PostView = () => {

    const { id } = useParams();
    const token = localStorage.getItem("token");
    const [post, setPost] = useState(null);
    const [loading, setLoding] = useState(true)

    const getNewsFeed = async () => {
        try {
            setLoding(true)
            const response = await OldApi.getNewsFeedByID(token, id);
            setPost(response.data)
        } catch (e) {
            console.log("e", e);
        } finally {
            setLoding(false)
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        getNewsFeed()
    }, [id]);

    return (
        <Sidebar>
            <PageTitle title="Post" />
            <Container fluid className="main-content dashboard-main">
                <div className="dash-content">
                    {
                        loading ? (
                            <div className="d-flex align-items-center justify-content-center mb-5" style={{ height: "500px" }}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ color: "#ff4814" }}
                                />
                            </div>
                        ) : (
                            <PostContent post={post} />
                        )
                    }
                </div>
                <Footer className="simple-container" />
            </Container>
        </Sidebar>
    )
}

export default PostView