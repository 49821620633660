import { Button, Card, Empty, Flex, Space, Typography } from 'antd';
import PostItem from './PostItem';
import { FaChevronRight } from 'react-icons/fa6';
import { useState } from 'react';
import { ReactComponent as PostIcon } from '../../../assets/svgs/post.svg';
import { useToggle } from '../../../shared/hooks/useToggle';
import StartupCreatePostModal from './StartupCreatePostModal';

const DEFAULT_NUMBER_OF_POSTS = 3;

interface Props {
  canUpdate: boolean;
  activities: any[];
  refetch: () => void;
}
const PostList = ({ canUpdate, activities, refetch }: Props) => {
  const [showAllPosts, setShowAllPosts] = useState(false);
  const { open, onClose, onOpen } = useToggle();

  const handleViewAllPosts = () => {
    setShowAllPosts(true);
  };

  return (
    <>
      <StartupCreatePostModal open={open} onClose={onClose} onCompleted={refetch} />

      <Card>
        <Flex justify="space-between" align="center">
          <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary">
            Activity
          </Typography.Title>
          {canUpdate && (
            <Button type="default" size="small" onClick={onOpen}>
              Create a post
            </Button>
          )}
        </Flex>

        <div>
          {activities?.length ? (
            <Space direction="vertical" size={14} className="tw-w-full">
              {activities
                ?.slice(0, showAllPosts ? activities?.length : DEFAULT_NUMBER_OF_POSTS)
                ?.map((activity, index) => <PostItem key={`post-${index}`} {...activity} />)}

              {activities?.length > DEFAULT_NUMBER_OF_POSTS && !showAllPosts && (
                <Button
                  type="text"
                  className="tw-w-full tw-text-text-gray"
                  onClick={handleViewAllPosts}
                  icon={<FaChevronRight />}
                  iconPosition="end"
                >
                  Show More
                </Button>
              )}
            </Space>
          ) : (
            <Flex justify="center" align="center" className="tw-w-full tw-py-8">
              <Empty image={<PostIcon />} description="No post" />
            </Flex>
          )}
        </div>
      </Card>
    </>
  );
};

export default PostList;
