import { Avatar, Card, Empty, Flex, Space, Typography } from 'antd';
import { ReactComponent as LockIcon } from '../../../assets/svgs/lock.svg';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { ReactComponent as CompanyLogoDefault } from '../../../assets/svgs/company-logo-default.svg';

interface Props {
  profilePhoto?: string;
  coverPhoto?: string;
  companyName?: string;
}

const HeadingSectionPrivate = ({ profilePhoto, coverPhoto, companyName }: Props) => {
  return (
    <>
      <Card
        cover={
          <>
            {coverPhoto ? (
              <img alt="example" src={coverPhoto} className="tw-h-[360px] tw-bg-contain tw-bg-center tw-bg-no-repeat" />
            ) : (
              <div className="tw-bg-background-black tw-text-center">
                <Logo />
              </div>
            )}
          </>
        }
      >
        <div className="tw-space-y-3">
          <Flex justify="space-between" gap={8} align="center">
            <Space size={12}>
              <div className="tw-absolute tw-left-6 tw-top-[312px]">
                <Avatar
                  src={profilePhoto || <CompanyLogoDefault />}
                  size={153}
                  className="tw-border-4 tw-border-text-white"
                />
              </div>
              <div className="tw-space-y-1 tw-pl-40">
                <Typography.Title level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                  {companyName}
                </Typography.Title>
              </div>
            </Space>
          </Flex>
        </div>

        <Empty image={<LockIcon />} description="This profile is private" />
      </Card>
    </>
  );
};

export default HeadingSectionPrivate;
