import { useEffect } from 'react';
import { getToken } from '../utils/token';
import useAuthStore from '../stores/auth.store';
import useUserStore from 'src/shared/stores/user.store';
import useRoleStore from 'src/shared/stores/role.store';
import UserService from 'src/api/services/user/user.service';
import { toast } from 'react-toastify';
import useInactivityTimer from './useInactivityTimer';

const useAuth = () => {
  const { isAuthenticated, login, setLoading } = useAuthStore();
  const { email, setUser } = useUserStore();
  const { currentRole, setRoles, setCurrentRole } = useRoleStore();

  // Load previous session
  useEffect(() => {
    const setCurrentUser = async () => {
      try {
        const userService = new UserService();

        const response = await userService.getUserCurrent();
        if (!response.success) {
          throw new Error(response.message);
        }
        const { firstName, lastName, email, profilePhoto, companyName, companyProfilePhoto, role } =
          response.data;
        setUser({
          firstName,
          lastName,
          email,
          profilePhoto,
          companyName,
          companyProfilePhoto,
        });
        if (role?.length) {
          setRoles(role);
          setCurrentRole(role[0]);
        }
      }
      catch (error: any) {
        toast.error(error.message);
      }
      finally {
        // Signal loading complete, e.g. to PrivateRoute
        setLoading(false);
      }
    };

    // TODO Use Supabase session/cookie
    const token = getToken();
    if (!token) {
      setLoading(false);
      return;
    }
    if (!isAuthenticated) {
      login(token, true);
    }
    if (!email || !currentRole) {
      setCurrentUser();
    }
    else {
      // Done, no need to await fetch
      setLoading(false);
    }
  }, [currentRole, email, isAuthenticated, login, setCurrentRole, setLoading, setRoles, setUser]);

  useInactivityTimer();
};

export default useAuth;
