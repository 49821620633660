import useRoleStore from '../../../shared/stores/role.store';
import ProfileService from '../../../api/services/profiles/profile.service';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PROFILE_PATHS from '../../../routes/profile.paths';
import { Role } from '../../../shared/stores/role.store';

type SelectRoleFormData = {
  roles: Role[];
};

const useSelectRoleForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setRoles, setCurrentRole } = useRoleStore();

  const handleSubmit = async (data: SelectRoleFormData) => {
    try {
      setLoading(true);

      const { roles } = data;

      const response = await ProfileService.basicProfile({
        role: JSON.stringify(roles),
        profileFlag: roles?.length === 2 ? 1 : roles?.length === 1 && roles?.includes('investor') ? 2 : 3,
      });
      if (response?.success) {
        setRoles(roles);
        setCurrentRole(roles[0]);
        navigate(`${PROFILE_PATHS.SETUP}`);
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};

export default useSelectRoleForm;
