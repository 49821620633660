import React, { forwardRef, useState } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import FormLabel from "./FormLabel";
import InputField from "./InputField";
import OldApi from "../api-old/api";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { interestCategories } from "../constants";
import { handleKeyDown } from "../utils";
import ReactDatePicker from "react-datepicker";

const EditModal = ({ show, onClose, data, getInvestment }) => {
  const [formInvestment, setFormInvestment] = useState(data);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const handleInvestmentChange = (e) => {
    const { name, value } = e.target;
    setFormInvestment({
      ...formInvestment,
      [name]: value,
    });
  };
  
  const handleInvestmentDateChange = (date) => {
    setFormInvestment({
      ...formInvestment,
      dealDate: date,
    });
  };

  const handleSubmit = async () => {
    const data = {
      companyName: formInvestment?.companyName,
      dealDate: formInvestment?.dealDate,
      dealType: formInvestment?.dealType,
      dealSize: formInvestment?.dealSize,
      industry: formInvestment?.industry,
      companyStage: formInvestment?.companyStage,
      leadInvestor: formInvestment?.leadInvestor,
    };
    try {
      setLoading(true);
      const response = await OldApi.updateInvestment(
        data,
        formInvestment?.id,
        token
      );
      if (response?.success) {
        toast.success("Investment Updated Succcessfully");
        getInvestment(1, 5);
        onClose();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      toast.error(e?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} className="edit-modal-investment" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>
          <h1 className="profile-heading mx-3 mt-3">Edit Investment</h1>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="investment-row mt-1">
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Company Name" />
              <InputField
                className="form-input"
                placeholder="Enter Company Name"
                type="text"
                name="companyName"
                value={formInvestment?.companyName}
                onChange={handleInvestmentChange}
              />
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Deal Date" />
              {/* <DatePicker
                selected={
                  formInvestment.dealDate
                    ? new Date(formInvestment.dealDate)
                    : null
                }
                onChange={handleInvestmentDateChange}
                dateFormat="MM/yyyy"
                placeholderText="mm | yyyy"
                showMonthYearPicker
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                customInput={<CustomInput />}
              /> */}
              <ReactDatePicker
                className="form-input date-input"
                selected={
                  formInvestment.dealDate
                    ? new Date(formInvestment.dealDate)
                    : null
                }
                onChange={handleInvestmentDateChange}
                dateFormat="MM/yyyy"
                placeholderText="mm | yyyy"
                showMonthYearPicker
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={15}
              />
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Deal Type" />
              <select
                name="dealType"
                className="form-input form-select-select"
                value={formInvestment.dealType}
                onChange={handleInvestmentChange}
              >
                <option value="" disabled hidden>
                  Select Type
                </option>
                <option value="Safe">Safe</option>
                <option value="Convertible">Convertible</option>
                <option value="Preferred Stock">Preferred Stock</option>
                <option value="Common Stock">Common Stock</option>
                <option value="Ideation">Ideation</option>
                <option value="Debt Financing">Debt Financing</option>
              </select>
              {/* <InputField
                className="form-input"
                placeholder="Enter Type"
                type="text"
                name="dealType"
                value={formInvestment?.dealType}
                onChange={handleInvestmentChange}
              /> */}
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Deal Size" />
              <InputField
                className="form-input"
                placeholder="Enter Deal Size"
                type="text"
                name="dealSize"
                value={formInvestment?.dealSize}
                onChange={handleInvestmentChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Industry" />
              <select
                name="industry"
                className="form-input form-select-select"
                onChange={handleInvestmentChange}
                value={formInvestment?.industry}
              >
                <option value="" selected>
                  {"Select Industry"}
                </option>
                {interestCategories?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {/* <InputField
                className="form-input"
                placeholder="Enter Industry"
                type="text"
                name="industry"
                value={formInvestment?.industry}
                onChange={handleInvestmentChange}
              /> */}
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Company Stage" />
              <select
                name="companyStage"
                className="form-input form-select-select"
                value={formInvestment.companyStage}
                onChange={handleInvestmentChange}
              >
                <option value="" disabled hidden>
                  Select Stage
                </option>
                <option value="Pre-seed">Pre-seed</option>
                <option value="Seed">Seed</option>
                <option value="Series A">Series A</option>
                <option value="Series B">Series B</option>
                <option value="Ideation">Ideation</option>
              </select>
            </div>
          </Col>
          <Col lg={6} md={12} xs={12}>
            <div className="form-group d-flex flex-column gap-2">
              <FormLabel className="form-label" labelText="Lead Investor " />
              <InputField
                className="form-input"
                placeholder="Investor Name"
                type="text"
                name="leadInvestor"
                value={formInvestment?.leadInvestor}
                onChange={handleInvestmentChange}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <div className="modal-update d-flex justify-content-end gap-3 align-items-center w-100">
        <button className="view-button mt-0" onClick={onClose}>
          Cancel
        </button>
        <button
          className="primary-btn modal-btn mt-0"
          onClick={() => handleSubmit()}
        >
          Update{" "}
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <></>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default EditModal;
