import { useEffect } from "react";
import AuthLayout from "../../modules/auth/components/AuthLayout";
import LoginForm from "../../modules/auth/components/LoginForm";
import useAuthStore from "../../modules/auth/stores/auth.store";
import { useNavigate, useSearchParams } from "react-router-dom";

const LoginPage = () => { 
  const {isAuthenticated} = useAuthStore();
  const [searchParms] = useSearchParams();
  const navigate = useNavigate();

  // TODO Move to useAuth
  useEffect(() => {
    if (isAuthenticated) {
      navigate(searchParms.get("redirect") || "/", { replace: true });
    }
  }, [isAuthenticated, navigate, searchParms]);

  return (
    <AuthLayout header="Login" title="Welcome Back" description="Welcome back, select method to login">
      <LoginForm />
    </AuthLayout>
  );
};

export default LoginPage;
