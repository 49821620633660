import { Image, ImageProps, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/modal/style';
import 'antd/lib/slider/style';
import { ReactNode } from 'react';
import { User } from '../../utils/images';
import useUpload from '../hooks/useUpload';
import { MdUploadFile } from 'react-icons/md';

interface Props {
  onChange?: (url: string) => void;
  file?: string;
  maxSizeInMB?: number;
  defaultImage?: string | ReactNode;
}

function UploadImage({
  onChange,
  file,
  width = 100,
  height = 100,
  maxSizeInMB = 5,
  defaultImage = User,
  ...rest
}: Props & Omit<ImageProps, 'onChange'>) {
  const { fileData, loading, handleUpload, handleBeforeUpload } = useUpload({ file, maxSizeInMB, onChange });

  return (
    <Spin spinning={loading}>
      <ImgCrop showGrid aspectSlider minZoom={0.5}>
        <Upload.Dragger
          accept="image/*"
          customRequest={handleUpload}
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          maxCount={1}
          className="tw-cursor-pointer"
          progress={{
            strokeWidth: 4,
            showInfo: false,
          }}
        >
          <MdUploadFile size={32} />
          <p className="ant-upload-text tw-mt-4 tw-text-base">Click or drag file to this area to upload</p>
        </Upload.Dragger>
      </ImgCrop>

      {fileData && <Image preview={false} src={fileData} alt="image" {...rest} />}
    </Spin>
  );
}

export default UploadImage;
