import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useCreatePost } from '../hooks/useCreatePost';
import { CreatePostDto } from '../../../api/services/post/post.dto';
import UploadImage from '../../../shared/components/UploadImage';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const StartupCreatePostModal = ({ open, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<CreatePostDto>();
  const { handleCreatePost, loading } = useCreatePost({ onCompleted: () => {
    form.resetFields();
    onClose();
    onCompleted();
  } });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Create Post
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" size="small" onFinish={handleCreatePost}>
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item<CreatePostDto>
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please enter title' }]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item<CreatePostDto>
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please enter description' }]}
            >
              <Input.TextArea placeholder="Enter description" autoSize={{ minRows: 6 }} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Add image" name="file" rules={[{ required: true, message: 'Please add image' }]}>
              <UploadImage />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default StartupCreatePostModal;
