export const Pattern = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  profileCustomUrl: /^[a-zA-Z0-9/-]+$/,
}

export const Validator = {
  email: (value: string) => Pattern.email.test(value),
  url: (value: string) => Pattern.url.test(value),
  profileCustomUrl: (value: string) => Pattern.profileCustomUrl.test(value),
}
