import StartupService from '../../../api/services/profiles/startup.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useRoleStore from 'src/shared/stores/role.store';

interface Props {
  onCompleted: () => void;
}

export const useUpdateStartupProfile = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);
  const { roles, setRoles, setCurrentRole } = useRoleStore();

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await StartupService.updateStartupProfile(data);

      // TODO Update companyName and profilePhoto in state, for top menu
      if (response?.success) {
        if (!roles.includes('startup')) {
          setRoles([...roles, 'startup']);
          setCurrentRole('startup');
        }
        
        onCompleted();
        toast.success('Startup profile updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error: any) {
      toast.error(error.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
