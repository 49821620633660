import APIClient from '../../client';
import { BaseResponse } from '../../common';
import { CreatePostDto } from './post.dto';

const ROUTES = {
  CREATE_POST: '/api/create-news-feed',
};

const PostService = {
  createPost: (data: CreatePostDto | FormData): Promise<BaseResponse> => APIClient.post({ route: ROUTES.CREATE_POST, data })
}

export default PostService;

