import React, { useCallback, useContext, useEffect, useState } from 'react';
import Footer from './Footer';
import { Col, Container, Row } from 'react-bootstrap';
import FeedsCard from './FeedsCard';
import FeedsProfileCard from './FeedsProfileCard';
import { dummyProfile } from '../utils/images';
import OldApi from '../api-old/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../context/RoleProvider';
import PostModal from './PostModal';
import HelperBanner from './HelperBanner';
import useRoleStore from '../shared/stores/role.store';

const FeedsContent = () => {
  const navigate = useNavigate();
  const [investors, setInvestors] = useState();
  const [startups, setStartups] = useState();
  const [followedProfiles, setFollowedProfiles] = useState();
  const { currentRole } = useRoleStore();
  const { setActiveMenuItem } = useContext(RoleContext);
  const profilePhoto = localStorage?.getItem('profilePhoto');
  const companyProfilePhoto = localStorage?.getItem('companyProfilePhoto');
  const [newsFeed, setNewsFeed] = useState();
  const [showPostModal, setShowPostModal] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  const token = localStorage.getItem('token');

  const getNewsFeed = useCallback(async () => {
    try {
      const response = await OldApi.getNewsFeed(token, currentRole === 'investor' ? 'investor' : 'startup');
      if (response.success) {
        const reversedData = response.data.reverse();
        setNewsFeed(reversedData);
      }
    }
    catch (error) {
      toast.error(error.message);
    }
  }, [token, currentRole]);

  const getRecentProfiles = useCallback(
    async filter => {
      try {
        const response = await OldApi.getRecentProfiles(
          token,
          currentRole === 'investor' ? 'investor' : 'startup',
          '',
          filter,
        );
        if (response.success) {
          setInvestors(response?.data?.investors);
          setStartups(response?.data?.startups);
          setFollowedProfiles(response?.data?.followedProfiles);
          if (response?.data?.followedProfiles?.length >= 5) {
            setShowHelper(false);
          }
          else {
            setShowHelper(true);
          }
        }
      }
      catch (error) {
        toast.error(error.message);
      }
    },
    [token, currentRole],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getNewsFeed();
    getRecentProfiles('');
  }, [getNewsFeed, getRecentProfiles]);

  const handleClose = () => {
    setShowHelper(false);
  };

  const handlePostModalToggle = () => {
    setShowPostModal(!showPostModal);
  };

  const handleViewAllClick = value => {
    localStorage.setItem('activeMenuItem', value);

    setActiveMenuItem(value);
    navigate(`/${value}`);
  };

  return (
    <Container fluid className="main-content dashboard-main">
      <Row className="pt-4 pb-4 dash-content">
        <Col lg={8} md={12} sm={12} xs={12}>
          <h1 className="trending-heading  trend-heading mb-0">
            Your News feed
            <span className="secondary-text slash-trending">_</span>
          </h1>
          {showHelper && <HelperBanner onClose={handleClose} />}
          <div className="feeds-container mt-3">
            <div className=" d-flex gap-2 flex-row align-items-center w-100 justify-content-between">
              <div className="profilep-img profile-update-img">
                <img
                  src={
                    currentRole === 'investor' && profilePhoto && profilePhoto !== 'null'
                      ? profilePhoto
                      : currentRole === 'investor' &&
                          (!profilePhoto || profilePhoto === 'null' || profilePhoto === 'undefined')
                        ? dummyProfile
                        : currentRole === 'startup' &&
                            (!companyProfilePhoto ||
                              companyProfilePhoto === 'null' ||
                              companyProfilePhoto === 'undefined')
                          ? dummyProfile
                          : currentRole === 'startup' && companyProfilePhoto
                            ? companyProfilePhoto
                            : dummyProfile
                  }
                  alt=""
                />
              </div>
              <button className="btn-post" onClick={handlePostModalToggle}>
                Start a post
              </button>
            </div>
          </div>
          <PostModal show={showPostModal} onClose={() => setShowPostModal(false)} />

          <div className="feed-type custom-scrollbar justify-content-center">
            {newsFeed?.length > 0 ? (
              newsFeed?.map((newsfeed, index) => <FeedsCard newsfeed={newsfeed} />)
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                <p className=" joined-headings">No Posts found</p>
              </div>
            )}
          </div>
        </Col>
        <Col lg={4} md={12} xs={12}>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small mb-0">
              Startups
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {startups?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('startups')}
              >
                View All
              </button>
            )}
          </div>

          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {startups?.length > 0 ? (
              <>
                {startups.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type="startup" />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Startups Found</div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small mb-0">
              Investors
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {investors?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('investors')}
              >
                View All
              </button>
            )}
          </div>

          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {investors?.length > 0 ? (
              <>
                {investors.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type="investor" />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Investors Found</div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
            <h1 className="trending-heading-small ">
              Following
              <span className="secondary-text slash-trending-small">_</span>
            </h1>
            {followedProfiles?.length > 0 && (
              <button
                type="button"
                className="view-button trending-view-button mt-0"
                onClick={() => handleViewAllClick('followers')}
              >
                View All
              </button>
            )}
          </div>
          <div className="trending-profiles d-flex flex-column gap-4 pt-1 notification-row pb-4 custom-scrollbar">
            {followedProfiles?.length > 0 ? (
              <>
                {followedProfiles.slice(0, 6).map((item, idx) => (
                  <FeedsProfileCard key={idx} data={item} type={item?.companyName ? 'startup' : 'investor'} />
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 mb-5">No Profiles found</div>
            )}
          </div>
        </Col>
      </Row>
      <Footer className="simple-container mt-3" />
    </Container>
  );
};

export default FeedsContent;
