import React, { useEffect, useState } from "react";
import CoverImage from "../components/CoverImage";
import { DummyImage, CoverPhoto } from "../utils/images";
import { Col, Container, Row } from "react-bootstrap";
import SelectedCategories from "../components/SelectedCategories";
import ProfileUpload from "../components/ProfileUpload";
import UserDetails from "../components/UserDetails";
import TableInvestment from "../components/TableInvestment";
import ProfileRatingsSection from "../components/ProfileRatingsSection";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OldApi from "../api-old/api";
import { formatDate } from "../utils";
import { toast } from "react-toastify";
import FeedsCard from "../components/FeedsCard";
import Sidebar from "../components/Sidebar";
import { BsCopy } from "react-icons/bs";
import PdfDownload from "../components/pdfDownload";
import { config } from "../config";
import PageTitle from "../components/PageTitle";
import PROFILE_PATHS from "../routes/profile.paths";

const InvestorProfileView = () => {
  const location = useLocation();
  const [publicInvestments, setPublicInvestment] = useState([]);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [newsFeed, setNewsFeed] = useState([]);
  
  const [formValues, setFormValues] = useState({
    coverPhoto: null,
    profilePhoto: null,
    firstName: "",
    lastName: "",
    investorEmail: "",
    phoneNumber: "",
    country: "",
    city: "",
    address: "",
    state: "",
    interestCategories: [],
    about: "",
    investorStatus: "",
    investmentStages: [],
    mentor: "",
    joinDate: "",
    socialLinks: {},
    totalInvestment: null,
    handle: "",
  });

  const getInvestorProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await OldApi.getInvestorProfile(
        token,
        !location?.state?.data ? "" : location?.state?.data
      );
      if (response.success) {
        if (response?.data?.newsFeed) {
          setNewsFeed(response?.data?.newsFeed);
        }
        setNewsFeed(response?.data?.newsFeed);

        let profileImage = response?.data?.profilePhoto || null;
        let coverImage = response?.data?.coverPhoto || null;

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: coverImage,
          profilePhoto: profileImage,
          firstName: response?.data?.firstName || "",
          lastName: response?.data?.lastName || "",
          investorEmail: response?.data?.investorEmail || "",
          phoneNumber: response?.data?.phoneCode
            ? response?.data?.phoneCode + response?.data?.phoneNumber
            : response?.data?.phoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          state: response?.data?.state || "",
          address: response?.data?.address || "",
          interestCategories: response?.data?.interestCategories || [],
          about: response?.data?.about || "",
          investorStatus: response?.data?.investorStatus || "",
          investmentStages: response?.data?.investmentStages || "",
          mentor: response?.data?.mentor || "",
          joinDate: response?.data?.createdAt || "",
          socialLinks: response?.data?.socialLinks || {},
          totalInvestment: response?.data?.totalInvestment,
          handle: response?.data?.handle,
        }));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleCopy = () => {
    if (formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/investor/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else {
      toast.error("Set the profile URL first in the edit profile section.");
    }
  };

  const getInvestment = async (page, rowsPerPage) => {
    try {
      const response = await OldApi.getInvestment(token, page, rowsPerPage);
      if (response.success) {
        setPublicInvestment(response);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };


  useEffect(() => {
    getInvestorProfile();
    getInvestment(1, 5);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location?.state?.data, userId]);

  return (
    <Sidebar>
      <PageTitle title="My Profile" />
      <div className="main-content dashboard-main">
        <div id="section" className="dash-content">
          <CoverImage
            src={formValues?.coverPhoto ?? CoverPhoto}
            type="investor-profile"
          />
          <Container fluid className="footer-container">
            <Row className="bordered-profiles">
              <Col lg={7} md={6} xs={12}>
                <div className="d-flex  brand-details">
                  <ProfileUpload
                    src={
                      formValues?.profilePhoto
                        ? formValues?.profilePhoto
                        : DummyImage
                    }
                  />
                  <UserDetails formValues={formValues} />
                </div>
                <ProfileRatingsSection role={"investor"} formValues={formValues} />
              </Col>
              <Col lg={5} md={6} xs={12} className="p-0">
                <div className="investor-pager d-flex align-items-center gap-3 justify-content-end mt-4 ">
                  <Link
                    to={PROFILE_PATHS.INVESTOR_EDIT}
                    className="investor-link justify-content-end d-flex"
                  >
                    <button className="view-button follow-btn mt-0">
                      Edit Profile
                    </button>
                  </Link>
                  <PdfDownload labelText="Download" formValues={formValues} />

                  <BsCopy className="share-option" onClick={handleCopy} />
                </div>
                {formValues?.interestCategories?.length > 0 ? (
                  <div className="interested-categories interested-profile">
                    <h2 className="category-heading">Categories</h2>
                    <SelectedCategories
                      categories={formValues?.interestCategories}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Container>

          <Container fluid className="footer-container">
            <Row className="mt-3 about-bordered">
              <Col lg={12} md={12} xs={12}>
                <h1 className="about-heading mt-3">About Investor</h1>
                <p className="about-desc mt-3">
                  {formValues?.about ? formValues?.about : "..."}
                </p>
              </Col>
            </Row>
          </Container>

          <Container fluid className="footer-container">
            <Row className="mt-5 joined-bordered ">
              <Col lg={2} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">Join Date</span>
                  <span className="joined-desc">
                    {formValues?.joinDate
                      ? formatDate(formValues?.joinDate)
                      : "..."}
                  </span>
                </div>
              </Col>
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">Investor Status</span>
                  <span className="joined-desc">
                    {formValues?.investorStatus
                      ? formValues?.investorStatus
                      : `...`}
                  </span>
                </div>
              </Col>
              <Col lg={3} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">
                    Primary investment stages
                  </span>
                  <span className="joined-desc">
                    {formValues?.investmentStages
                      ? formValues.investmentStages.join(", ")
                      : `...`}
                  </span>
                </div>
              </Col>
              <Col lg={4} md={6} xs={12}>
                <div className="joined-table d-flex flex-column gap-4">
                  <span className="joined-headings">
                    Interested in providing mentorship to founders
                  </span>
                  <span className="joined-desc">
                    {formValues?.mentor ? formValues?.mentor : `...`}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="footer-container">
            <h1 className="profile-heading mt-4 mb-4">
              Investments{" "}
              <span className="secondary-text slash-trending">_</span>
            </h1>
            <TableInvestment
              rows={publicInvestments}
              pagination={publicInvestments?.pagination}
              getInvestment={getInvestment}
            />
          </Container>
          <Container fluid className="footer-container">
            <h1 className="profile-heading mb-2 mt-2">
              News/Updates
              <span className="secondary-text slash-trending">_</span>
            </h1>
            <div className="news-feeds mt-4 mb-4 custom-scrollbar">
              {newsFeed?.length > 0 ? (
                newsFeed?.map((newsfeed, index) => (
                  <FeedsCard
                    newsfeed={newsfeed}
                    text="startup-feed feeds-post d-flex justify-content-start mt-2 gap-3"
                  />
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5 ">
                  <p className="joined-headings">No Posts found</p>
                </div>
              )}
            </div>
          </Container>
        </div>
        <div className="mt-5">
          <Footer className="simple-container" />
        </div>
      </div>
    </Sidebar>
  );
};

export default InvestorProfileView;
