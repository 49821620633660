import { countries } from '../../../shared/constants/countries';
import { Select, SelectProps } from 'antd';

const countrySelectOptions = countries.map(country => ({
  value: country,
  label: country,
}));

const CountrySelect = ({ placeholder = 'Select Country', ...rest }: SelectProps) => {
  return <Select placeholder={placeholder} options={countrySelectOptions} {...rest} />;
};

export default CountrySelect;
