import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig, AxiosInstance } from 'axios';
import { config } from '../config';

const client: AxiosInstance = axios.create({
  baseURL: config?.ApiBaseURL,
  timeout: 15000, // 15 seconds timeout
  headers: {
    Accept: 'application/json, text/plain, */*',
  },
});

client.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => {
    if (req.headers && req.headers.Authorization) {
      req.headers.Authorization = `Bearer ${req.headers.Authorization}`;
    }

    if (req.headers) {
      req.headers['X-Request-ID'] = `${Date.now()}-${Math.random()}`;
    }

    return req;
  },
  (error: AxiosError) => {
    console.error('Request error:', error.message);
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean; _retryCount?: number };
    const status = error.response?.status;

    // Handle token expiration and refresh
    if (status === 401 && !originalRequest._retry) {
      // TODO Attempt to refresh token and retry the original request
    }
    // TODO 404, 500, etc. error handling
    console.warn(error.message, error.config?.url, error);

    return Promise.reject(error.response || error.message);
  },
);

// Request Wrapper with Request Cancellation
const request = async <U = {}>(options: AxiosRequestConfig): Promise<U> => {
  const controller = new AbortController();
  options.signal = controller.signal; // Attach the signal to support request cancellation

  const onSuccess = (response: AxiosResponse<U>): U => {
    // TODO Return entire response
    return response.data;
  };

  const onError = (error: AxiosError & { data?: U }): Promise<never> => {
    // TODO Return entire error
    return Promise.reject({
      ...error.data,
      status: error.status,
    });
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  }
  catch (error) {
    return onError(error as AxiosError);
  }
};

const cancelRequest = (message = 'Request cancelled by the user') => {
  const controller = new AbortController();
  controller.abort(message);
};

export { request, cancelRequest };
