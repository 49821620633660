import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import InvestmentDropdown from "./InvestmentDropdown";
import { formatDate } from "../utils";

const TableInvestment = ({
  rows,
  type,
  pagination,
  getInvestment,
  setInvestment,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    getInvestment(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getInvestment(1, newRowsPerPage);
  };

  return (
    <TableContainer component={Paper} className="investment-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Deal Date</TableCell>
            <TableCell>Deal Type</TableCell>
            <TableCell>Deal Size</TableCell>
            <TableCell>Industry</TableCell>
            <TableCell>Company Stage</TableCell>
            <TableCell>Lead investor</TableCell>
            {type === "edit-investment" ? <TableCell>Action</TableCell> : <></>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.data?.length > 0 ? (
            rows?.data?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row?.companyName}</TableCell>
                <TableCell>
                  {row?.dealDate
                    ? formatDate(row?.dealDate, "month-year")
                    : "-"}
                </TableCell>
                <TableCell>{row?.dealType}</TableCell>
                <TableCell>
                  {row?.dealSize.startsWith("$")
                    ? row?.dealSize
                    : "$" + row?.dealSize}
                </TableCell>
                <TableCell>{row?.industry}</TableCell>
                <TableCell>{row?.companyStage}</TableCell>
                <TableCell>{row?.leadInvestor}</TableCell>
                {type === "edit-investment" ? (
                  <TableCell>
                    <InvestmentDropdown
                      rowData={row}
                      getInvestment={getInvestment}
                    />
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                className="text-center"
                colSpan={type === "edit-investment" ? 8 : 7}
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={isNaN(pagination?.totalItems) ? 0 : pagination?.totalItems}
        rowsPerPage={rowsPerPage ?? 0}
        page={pagination?.currentPage - 1 ?? 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default TableInvestment;
