import APIClient from '../../client';
import { GetStartupProfileParams, StartupProfileResponse } from './profile.dto';

const STARTUP_ENDPOINTS = {
  GET_STARTUP_PROFILE: '/api/startup',
  UPDATE_STARTUP_PROFILE: '/api/startup-profile',
};

const StartupService = {
  getStartupProfile: ({ id, currentRole }: GetStartupProfileParams = {}): Promise<StartupProfileResponse> => {
    const url = currentRole
      ? `${STARTUP_ENDPOINTS.GET_STARTUP_PROFILE}/${currentRole}/${id ?? ''}`
      : `${STARTUP_ENDPOINTS.GET_STARTUP_PROFILE}/${id ?? ''}`;
    return APIClient.get({ route: url });
  },
  updateStartupProfile: (data: any): Promise<StartupProfileResponse> =>
    APIClient.put({ route: STARTUP_ENDPOINTS.UPDATE_STARTUP_PROFILE, data }),
};

export default StartupService;
