import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import ProfileCard from "../components/ProfileCard";
import Footer from "../components/Footer";
import OldApi from "../api-old/api";
import { toast } from "react-toastify";
import PageTitle from "../components/PageTitle";


const AllInvestors = () => {
  const token = localStorage.getItem("token");
  const [investors, setInvestors] = useState([]);
  const [page, setPage] = useState(2);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const rowsPerPage = 9;
  const getAllInvestors = async (page, rowsPerPage) => {
    try {
      const response = await OldApi.getAllInvestors(token, page, rowsPerPage);
      if (response.success) {
        setInvestors((prevInvestors) => [
          ...prevInvestors,
          ...response?.data?.investors,
        ]);
        setTotalPages(response?.data?.pagination?.totalPages);
        setPage(page + 1);
        setScrollToBottom(false);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoading(false);
      setSpinner(false);
    }
  };

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;

    if (scrolledToBottom && !loading) {
      setScrollToBottom(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  useEffect(() => {
    if (scrollToBottom && page <= totalPages) {
      setLoading(true);
      getAllInvestors(page, rowsPerPage);
    }
  }, [scrollToBottom]);

  useEffect(() => {
    getAllInvestors(1, rowsPerPage);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Sidebar>
      <PageTitle title="Investors" />
      <Container fluid className="main-content dashboard-main">
        <div className="dash-content">
          <div className="titles-trending d-flex justify-content-between align-items-center pt-4 pb-1">
            <h1 className="trending-heading mb-0">
              Investors
              <span className="secondary-text slash-trending">_</span>
            </h1>
          </div>
          {spinner ? (
            <div className="text-center visual-loader">
              <Spinner
                animation="border"
                role="status"
                style={{ width: "2rem", height: "2rem", color: "#ff4814" }}
              >
                <span className="visually-hidden ">Loading...</span>
              </Spinner>
              <p className="mt-2 joined-headings">Loading...</p>
            </div>
          ) : (
            <>
              {investors?.length ? (
                <Row className="card-profiles-row mt-5 mb-5">
                  {investors?.map((investor, index) => (
                    <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={12} key={index} className="mb-4">
                      <ProfileCard data={investor} type="investor" />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="d-flex justify-content-center align-items-center mt-5 mb-4">
                  No Investors Found
                </div>
              )}
            </>
          )}
        </div>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center mb-5">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              style={{ color: "#ff4814" }}
            />
          </div>
        ) : (
          <></>
        )}
        <Footer className="simple-container" />
      </Container>{" "}
    </Sidebar>
  );
};

export default AllInvestors;
