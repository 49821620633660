import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type Role = 'investor' | 'startup';

interface RoleState {
  currentRole?: Role;
  roles: Role[];
}

interface RoleAction {
  setCurrentRole: (role: Role) => void;
  setRoles: (roles: Role[]) => void;
}

const useRoleStore = create(
  persist<RoleState & RoleAction>(
    set => ({
      roles: [],
      setCurrentRole: (currentRole: Role) => set({ currentRole }),
      setRoles: (roles: Role[]) => set({ roles }),
    }),
    {
      name: 'role-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
export default useRoleStore;
