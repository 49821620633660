import StartupProfile from '../modules/profiles/components/StartupProfile';
import { useParams } from 'react-router-dom';
// @ts-ignore
import Sidebar from '../components/Sidebar';
import { Grid } from 'antd';

const StartupViewPage = () => {
  const { handle } = useParams();
  const { lg } = Grid.useBreakpoint();

  return (
    <Sidebar>
      <div className={`${lg ? 'tw-pl-[14rem]' : ''} tw-mx-auto tw-w-full tw-max-w-[1200px] tw-py-8`}>
        <StartupProfile handle={handle} />
      </div>
    </Sidebar>
  );
};

export default StartupViewPage;
