import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from 'react-icons/ri';
import { dummyProfile } from '../utils/images';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../modules/auth/stores/auth.store';
import AUTH_PATHS from '../routes/auth.paths';
import PROFILE_PATHS from '../routes/profile.paths';
import useRoleStore from '../shared/stores/role.store';
import useUserStore from '../shared/stores/user.store';

const ProfileSwitcher = () => {
  const { logout } = useAuthStore();
  const navigate = useNavigate();
  const { roles, currentRole, setCurrentRole } = useRoleStore();
  const { firstName, lastName, profilePhoto, companyName, companyProfilePhoto } = useUserStore();
  const userName = firstName && lastName ? `${firstName} ${lastName}` : '';
  const profileFlag = roles?.length === 2 ? '1' : roles?.length === 1 && roles?.includes('investor') ? '2' : '3';

  const handleLogout = () => {
    logout();
    navigate(AUTH_PATHS.LOGIN);
  };

  const handleRole = () => {
    setCurrentRole(currentRole === 'startup' ? 'investor' : 'startup');
  };

  return (
    <div className="profile-dropdown d-flex flex-row gap-2 align-items-center">
      <div className="profilep-img">
        <img
          src={
            currentRole === 'investor' && profilePhoto !== '' && profilePhoto !== 'null' && profilePhoto
              ? profilePhoto
              : currentRole === 'investor' &&
                  (profilePhoto === 'null' || profilePhoto === '' || profilePhoto === 'undefined')
                ? dummyProfile
                : currentRole === 'startup' && (companyProfilePhoto === 'null' || companyProfilePhoto === 'undefined')
                  ? dummyProfile
                  : currentRole === 'startup' && companyProfilePhoto
                    ? companyProfilePhoto
                    : dummyProfile
          }
          alt=""
        />
      </div>
      <div className="owner-profile d-flex  flex-column gap-1">
        <h1 className="owner-name mb-0">
          {currentRole === 'investor' && userName && userName !== 'null'
            ? userName.length > 9
              ? userName?.substring(0, 9) + '...'
              : userName
            : currentRole === 'startup' && companyName && companyName !== 'null'
              ? companyName.length > 9
                ? companyName?.substring(0, 9) + '...'
                : companyName
              : ''}
        </h1>
        <Dropdown className="settings-dropdown">
          <Dropdown.Toggle id="dropdown-basic" className="d-flex align-items-center gap-1">
            {currentRole.charAt(0).toUpperCase() + currentRole.slice(1)}
            <RiArrowDownSLine className="arrow-down" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="profile-dropdown">
            <Link className="profile-item" onClick={() => handleRole()}>
              <Dropdown.Item
                onClick={() =>
                  profileFlag === '2' && currentRole !== 'startup'
                    ? navigate(PROFILE_PATHS.STARTUP_YOUR)
                    : profileFlag === '3' && currentRole !== 'investor'
                      ? navigate(PROFILE_PATHS.INVESTOR_EDIT)
                      : navigate('/')
                }
              >
                {profileFlag === '2' && currentRole === 'startup'
                  ? 'Switch to Investor'
                  : profileFlag === '3' && currentRole === 'investor'
                    ? 'Switch to Startup'
                    : profileFlag === '2'
                      ? 'Create Startup Profile'
                      : profileFlag === '3'
                        ? 'Create Investor Profile'
                        : profileFlag === '1' && currentRole === 'startup'
                          ? 'Switch to Investor'
                          : 'Switch to Startup'}
              </Dropdown.Item>
            </Link>
            <Link
              className="profile-item"
              onClick={() =>
                navigate(currentRole === 'startup' ? PROFILE_PATHS.STARTUP_YOUR : PROFILE_PATHS.INVESTOR_YOUR)
              }
            >
              <Dropdown.Item
                disabled={
                  profileFlag === '2' && roles?.length < 2 && roles?.includes('investor') && currentRole === 'investor'
                    ? false
                    : profileFlag === '2' &&
                        roles?.length < 2 &&
                        roles?.includes('investor') &&
                        currentRole === 'startup'
                      ? true
                      : profileFlag === '3' &&
                          roles?.length < 2 &&
                          roles?.includes('startup') &&
                          currentRole === 'startup'
                        ? false
                        : profileFlag === '3' &&
                            roles?.length < 2 &&
                            roles?.includes('startup') &&
                            currentRole === 'investor'
                          ? true
                          : profileFlag === '1'
                            ? false
                            : true
                }
              >
                Profile View
              </Dropdown.Item>
            </Link>
            {currentRole === 'investor' && (
              <Link
                className="profile-item"
                onClick={() =>
                  navigate(currentRole === 'startup' ? PROFILE_PATHS.STARTUP_YOUR : PROFILE_PATHS.INVESTOR_EDIT)
                }
              >
                <Dropdown.Item
                  disabled={
                    profileFlag === '2' &&
                    roles?.length < 2 &&
                    roles?.includes('investor') &&
                    currentRole === 'investor'
                      ? false
                      : profileFlag === '2' &&
                          roles?.length < 2 &&
                          roles?.includes('investor') &&
                          currentRole === 'startup'
                        ? true
                        : profileFlag === '3' &&
                            roles?.length < 2 &&
                            roles?.includes('startup') &&
                            currentRole === 'startup'
                          ? false
                          : profileFlag === '3' &&
                              roles?.length < 2 &&
                              roles?.includes('startup') &&
                              currentRole === 'investor'
                            ? true
                            : profileFlag === '1'
                              ? false
                              : true
                  }
                >
                  Edit Profile
                </Dropdown.Item>
              </Link>
            )}
            <Link className="profile-item" onClick={() => navigate(AUTH_PATHS.UPDATE_PASSWORD)}>
              <Dropdown.Item>Update Password</Dropdown.Item>
            </Link>
            <Link className="profile-item">
              <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ProfileSwitcher;
