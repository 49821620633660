import Chat from '../views/chat';
import Feeds from '../views/feeds';
import InvestorEditProfile from '../views/investorEditProfile';
import InvestorPublicView from '../views/investorPublicView';
import InvestorProfileView from '../views/investorProfileView';
import PostView from '../views/postView';
import {
  home,
  chat,
  investors,
  startups,
  followers,
  userSettings,
  postView,
} from './paths';
import AllInvestors from '../views/allInvestors';
import AllStartups from '../views/allStartups';
import AllFollowers from '../views/allFollowers';
import Settings from '../views/settings';
import UpdatePassword from '../views/updatePassword';
import StartupViewPage from '../views/StartupViewPage';
import AUTH_PATHS from './auth.paths';
import PROFILE_PATHS from './profile.paths';

// TODO Organize by module
// TODO Use nested Route components instead of an array, for better code readability
export const PrivateRoutes = [
  {
    // TODO title isn't used, remove
    title: 'Home',
    component: Feeds,
    path: '/',
  },
  // TODO Old, remove after fixing old components hardcoded paths
  {
    title: 'Home',
    component: Feeds,
    path: home,
  },
  {
    title: 'Chat',
    component: Chat,
    path: chat,
  },
  {
    title: 'Startup Public view',
    component: StartupViewPage,
    path: PROFILE_PATHS.STARTUP_PUBLIC,
  },
  {
    title: 'Startup Profile view',
    component: StartupViewPage,
    path: PROFILE_PATHS.STARTUP_YOUR
  },
  // Deprecated, remove
  {
    title: 'Investor Edit Profile',
    component: InvestorEditProfile,
    path: PROFILE_PATHS.INVESTOR_EDIT,
  },
  {
    title: 'Investor Public View',
    component: InvestorPublicView,
    path: PROFILE_PATHS.INVESTOR_PUBLIC,
  },
  {
    title: 'Investor Profile View',
    component: InvestorProfileView,
    path: PROFILE_PATHS.INVESTOR_YOUR,
  },
  {
    title: 'Investors',
    component: AllInvestors,
    path: investors,
  },
  {
    title: 'Startups',
    component: AllStartups,
    path: startups,
  },
  {
    title: 'Followers',
    component: AllFollowers,
    path: followers,
  },
  {
    title: 'User Setting',
    component: Settings,
    path: userSettings,
  },
  {
    title: 'Change Password',
    component: UpdatePassword,
    path: AUTH_PATHS.UPDATE_PASSWORD,
  },
  {
    title: 'Post View',
    component: PostView,
    path: postView,
  },
];
