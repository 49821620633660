import { Button, Col, Form, Input, Row } from 'antd';
import CountrySelector from './CountrySelect';
import InvestmentStageSelect from './InvestmentStageSelect';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import IndustrySelector from './IndustrySelect';
import { useInvestorSetupForm } from '../hooks/useInvestorSetupForm';
import useUserStore from 'src/shared/stores/user.store';
import { ReactComponent as InvestorLogoDefault } from '../../../assets/svgs/investor-logo-default.svg';

interface Props {
  onNext: () => void;
}

const InvestorProfileSetupForm = ({ onNext }: Props) => {
  const { email } = useUserStore();
  const [form] = Form.useForm();
  const { handleUpdateInvestorProfile, loading } = useInvestorSetupForm({ onCompleted: onNext });

  return (
    <Form form={form} layout="vertical" size="large" className="tw-space-y-10" onFinish={handleUpdateInvestorProfile}>
      <Row gutter={[16, 0]}>
        <Col span={24} className="tw-flex tw-justify-center">
          <Form.Item name="profilePhoto" className="tw-w-fit tw-flex-shrink-0">
            <UploadAvatar defaultImage={<InvestorLogoDefault />} />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Please select your country' }]}>
            <CountrySelector showSearch />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item
            label="Email"
            name="investorEmail"
            initialValue={email}
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Investment Stages" name="investmentStages">
            <InvestmentStageSelect placeholder="Select Investment Stages" mode="multiple" maxTagCount={2} allowClear />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="interestCategories" label="Sectors of Interest">
            <IndustrySelector placeholder="Select Sector of Interest" mode="multiple" maxTagCount={2} allowClear />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button htmlType="submit" type="primary" className="tw-w-full" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InvestorProfileSetupForm;
