export const AUTH_ROUTES = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  SEND_VERIFICATION_EMAIL: '/auth/send-email',
  FORGOT_PASSWORD: '/auth/forget',
  UPDATE_PASSWORD: '/auth/update-password',
  CHANGE_PASSWORD: '/auth/change-password',
  VERIFY_EMAIL: '/auth/verify-email',
  OTP_STATUS: '/auth/otp-status',
  SEND_OTP: '/auth/send-otp',
  VERIFY_OTP: '/auth/verify-otp',
};
