
const AUTH_PATHS = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  VERIFY_RESULT: '/auth/verify/:result',
  // Copied from old paths, update as needed
  UPDATE_PASSWORD: '/change-password',
};

export default AUTH_PATHS;
