import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * Generates a PDF of the current profile and saves it to the user's downloads
 * folder. If `companyName` is provided, it is used as the filename, otherwise
 * the filename is "profile.pdf".
 *
 * @param {object} options - An object with the following properties:
 *   - `companyName`: A string to use as the filename for the PDF.
 */
export const generateProfilePDF = ({ companyName }: { companyName?: string }) => {
  const section = document.getElementById('section');

  if (!section) {
    return;
  }

  html2canvas(section, { useCORS: true }).then(canvas => {
    const pdf = new jsPDF();
    const imgWidth = pdf.internal.pageSize.getWidth() - 20;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const margin = 5;

    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, margin, imgWidth, imgHeight);

    if (companyName) {
      pdf.save(`${companyName}-profile.pdf`);
    } else {
      pdf.save("profile.pdf");
    }

  }).catch(error => {
    console.error('Error generating PDF:', error);
  });
};

