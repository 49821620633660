import ProfileService from '../../../api/services/profiles/profile.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { b64toBlob } from '../../../shared/utils/files';

interface Props {
  onCompleted: () => void;
}

export const useStartupSetupForm = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (key === 'interestCategories') {
          formData.append(key, JSON.stringify(value ?? []).replace('[', '{')
            .replace(']', '}'));
        }
        else if (value) {
          const formValue = key === 'profilePhoto' ? b64toBlob(String(value)) : String(value);
          formData.append(key, formValue);
        }
      });

      const response = await ProfileService.updateStartupProfile(formData);
      if (response?.success) {
        onCompleted();
        toast.success('Startup profile updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
