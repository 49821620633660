import React, { useEffect, useState } from "react";
import CoverImage from "../components/CoverImage";
import { Col, Container, Row } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import UserDetails from "../components/UserDetails";
import SelectedCategories from "../components/SelectedCategories";
import ProfileUpload from "../components/ProfileUpload";
import ProfileRatingsSection from "../components/ProfileRatingsSection";
import {
  DummyImage,
  CoverPhoto,
  PrivateProfile,
  Error404Image,
} from "../utils/images";
import TableInvestment from "../components/TableInvestment";
import Footer from "../components/Footer";
import OldApi from "../api-old/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../utils";
import { toast } from "react-toastify";
import { config } from "../config";
import useRoleStore from "../shared/stores/role.store";
import FeedsCard from "../components/FeedsCard";
import Sidebar from "../components/Sidebar";
import LoaderGif from "../components/LoaderGif";
import PdfDownload from "../components/pdfDownload";
import PageTitle from "../components/PageTitle";

const InvestorPublicView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const userId = localStorage.getItem("userId");
  const { currentRole } = useRoleStore();
  const [profileActive, setProfileActive] = useState(false);
  const [loader, setLoader] = useState(true);

  const [newsFeed, setNewsFeed] = useState([]);
  const [publicInvestments, setPublicInvestment] = useState([]);
  const token = localStorage.getItem("token");

  const [formValues, setFormValues] = useState({
    coverPhoto: null,
    profilePhoto: null,
    firstName: "",
    lastName: "",
    investorEmail: "",
    phoneNumber: "",
    country: "",
    city: "",
    address: "",
    state: "",
    interestCategories: [],
    about: "",
    investorStatus: "",
    investmentStages: "",
    mentor: "",
    joinDate: "",
    socialLinks: {},
    isFollowing: false,
    totalInvestment: null,
    isPrivateProfile: false,
    handle: "",
  });

  const getInvestorProfile = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await OldApi.getInvestorProfile(
        token,
        !id ? "" : id,
        currentRole === "investor" ? "investor" : "startup"
      );
      if (response.success) {        
        if (response?.data?.newsFeed) {
          setNewsFeed(response?.data?.newsFeed);
        }

        let profileImage = response?.data?.profilePhoto || null;
        let coverImage = response?.data?.coverPhoto || null;

        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          coverPhoto: coverImage,
          profilePhoto: profileImage,
          firstName: response?.data?.firstName || "",
          lastName: response?.data?.lastName || "",
          investorEmail: response?.data?.investorEmail || "",
          phoneNumber: response?.data?.phoneCode
            ? response?.data?.phoneCode + response?.data?.phoneNumber
            : response?.data?.phoneNumber || "",
          country: response?.data?.country || "",
          city: response?.data?.city || "",
          state: response?.data?.state || "",
          address: response?.data?.address || "",
          interestCategories: response?.data?.interestCategories || [],
          about: response?.data?.about || "",
          investorStatus: response?.data?.investorStatus || "",
          investmentStages: response?.data?.investmentStages || "",
          mentor: response?.data?.mentor || "",
          joinDate: response?.data?.createdAt || "",
          socialLinks: response?.data?.socialLinks || {},
          isFollowing: response?.data?.isFollowing || false,
          totalInvestment: response?.data?.totalInvestment,
          isPrivateProfile: response?.data?.isPrivateProfile || false,
          handle: response?.data?.handle || "",
        }));

        setProfileActive(true);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  const getInvestment = async (page, rowsPerPage) => {
    try {
      const response = await OldApi.getInvestment(
        token,
        page,
        rowsPerPage,
        id ? id : location?.state?.data
      );
      if (response.success) {
        setPublicInvestment(response);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCopy = () => {
    if (formValues?.handle) {
      navigator.clipboard
        .writeText(`${config?.AppBaseURL}/investor/${formValues?.handle}`)
        .then(() => {
          toast.success("Profile URL copied!");
        })
        .catch((error) => {
          console.error("Error copying URL: ", error);
        });
    } else {
      toast.error("Set the profile URL first in the edit profile section.");
    }
  };

  const handleFollow = async (e) => {
    e.preventDefault();
    try {
      const response = await OldApi.followProfile(
        {
          followingId: location?.state?.data,
          type: "investor",
          followerRole: currentRole === "investor" ? "investor" : "startup",
        },
        token
      );
      if (response.success) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          isFollowing: true,
        }));
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleUnFollow = async (e) => {
    e.preventDefault();
    try {
      const response = await OldApi.unFollowProfile(
        {
          followingId: location?.state?.data,
          type: "investor",
          followerRole: currentRole === "investor" ? "investor" : "startup",
        },
        token
      );
      if (response.success) {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          isFollowing: false,
        }));
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getInvestorProfile();
    getInvestment(1, 5);
  }, [id, userId]);

  return (
    <>
      {loader ? (
        <LoaderGif />
      ) : profileActive ? (
        <Sidebar>
          <PageTitle title="Profile" />
          <div className="main-content dashboard-main">
            <div id="section" className="dash-content">
              <CoverImage
                src={formValues?.coverPhoto ?? CoverPhoto}
                type="investor-profile"
              />
              <Container fluid className="footer-container">
                <Row className="bordered-profiles">
                  <Col lg={7} md={6} xs={12}>
                    <div className="d-flex brand-details">
                      <ProfileUpload
                        src={
                          formValues?.profilePhoto
                            ? formValues?.profilePhoto
                            : DummyImage
                        }
                      />
                      <UserDetails formValues={formValues} />
                    </div>
                    {!formValues?.isPrivateProfile && (
                      <ProfileRatingsSection role={"investor"} formValues={formValues} />
                    )}
                  </Col>
                  <Col lg={5} md={6} xs={12} className="p-0">
                    <div className="investor-pager d-flex align-items-center gap-3 justify-content-end mt-4 ">
                      {!formValues?.isPrivateProfile && (
                        <PdfDownload
                          type="view-button follow-btn capture-btn mt-0 d-flex align-items-center justify-content-center gap-1"
                          labelText="Download Page as PDF"
                          formValues={formValues}
                        />
                      )}
                      <button
                        className="primary-btn follow-button"
                        onClick={(e) =>
                          !formValues?.isFollowing
                            ? handleFollow(e)
                            : handleUnFollow(e)
                        }
                      >
                        {formValues?.isFollowing ? "Following" : "Follow"}
                      </button>
                      {!formValues?.isPrivateProfile && (
                        <BsCopy
                          className="share-options"
                          onClick={handleCopy}
                        />
                      )}
                    </div>
                    {!formValues?.isPrivateProfile &&
                      formValues?.interestCategories?.length > 0 && (
                      <div className="interested-categories">
                        <h2 className="category-heading">Categories</h2>
                        <SelectedCategories
                          categories={formValues?.interestCategories}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

              {!formValues?.isPrivateProfile ? (
                <>
                  <Container fluid className="footer-container">
                    <Row className="mt-3 about-bordered">
                      <Col lg={12} md={12} xs={12}>
                        <h1 className="about-heading mt-3">About Investor</h1>
                        <p className="about-desc mt-3">
                          {formValues?.about ? formValues?.about : `...`}
                        </p>
                      </Col>
                    </Row>
                  </Container>

                  <Container fluid className="footer-container">
                    <Row className="mt-5 joined-bordered ">
                      <Col lg={2} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">Join Date</span>
                          <span className="joined-desc">
                            {formValues?.joinDate
                              ? formatDate(formValues?.joinDate)
                              : "..."}
                          </span>
                        </div>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">
                            Investor Status
                          </span>
                          <span className="joined-desc">
                            {formValues?.investorStatus
                              ? formValues?.investorStatus
                              : `...`}
                          </span>
                        </div>
                      </Col>
                      <Col lg={3} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">
                            Primary investment stages
                          </span>
                          <span className="joined-desc">
                            {formValues?.investmentStages
                              ? formValues.investmentStages.join(", ")
                              : `...`}
                          </span>
                        </div>
                      </Col>
                      <Col lg={4} md={6} xs={12}>
                        <div className="joined-table d-flex flex-column gap-4">
                          <span className="joined-headings">
                            {" "}
                            Interested in providing mentorship to founders
                          </span>
                          <span className="joined-desc">
                            {formValues?.mentor ? formValues?.mentor : `...`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  <Container fluid className="footer-container">
                    <h1 className="profile-heading mt-4 mb-4">
                      Investments{" "}
                      <span className="secondary-text slash-trending">_</span>
                    </h1>
                    <TableInvestment
                      rows={publicInvestments}
                      pagination={publicInvestments?.pagination}
                      getInvestment={getInvestment}
                    />
                  </Container>
                </>
              ) : (
                <div className="d-flex flex-row gap-2 align-items-center justify-content-center mt-4">
                  <img
                    style={{ height: "auto", width: "40px" }}
                    src={PrivateProfile}
                    alt="PrivateProfile"
                  />
                  <span className="private-profile">
                    This profile is private
                  </span>
                </div>
              )}
              {!formValues?.isPrivateProfile && (
                <Container fluid className="footer-container mb-4 mt-3">
                  <div className="news-section d-flex justify-content-between align-items-center">
                    <h1 className="profile-heading mb-0">
                      News/Updates
                      <span className="secondary-text slash-trending">
                        _
                      </span>
                    </h1>
                    <div className="d-flex gap-4 mb-1">
                      <button
                        className="primary-btn feed-btn"
                        onClick={() => navigate("/home")}
                      >
                        Show All
                      </button>
                    </div>
                  </div>
                  <div className="news-feeds mt-4 mb-4 d-flex flex-column gap-4 custom-scrollbar ">
                    {newsFeed?.length > 0 ? (
                      newsFeed?.map((newsfeed, index) => (
                        <FeedsCard
                          newsfeed={newsfeed}
                          text="startup-feed feeds-post d-flex justify-content-start mt-2 gap-3"
                        />
                      ))
                    ) : (
                      <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                        <p className="joined-headings">
                          No News/Updates found
                        </p>
                      </div>
                    )}
                  </div>
                </Container>
              )}
            </div>
            <div className="mt-5">
              <Footer className="simple-container" />
            </div>
          </div>
        </Sidebar>
      ) : (
        <div className="main-error">
          <div className="profile-exist-image">
            <img src={Error404Image} alt="404" />
            <div className="profile-exist mt-5 ">Error loading profile</div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorPublicView;
