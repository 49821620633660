import type { OverrideToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';

export const TAG: OverrideToken['Tag'] = {
  borderRadius: 8,
  borderRadiusLG: 8,
  borderRadiusSM: 8,
  colorBgBase: COLOR.background.gray,
  colorTextBase: COLOR.text.primary,
  fontSizeSM: 14,
  fontSizeLG: 14,
  fontSizeXL: 16,
};
