import React, { useState } from 'react'
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import useRoleStore from '../shared/stores/role.store';
import { dummyProfile } from '../utils/images';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import InputField from './InputField';
import FormLabel from './FormLabel';
import OldApi from '../api-old/api';
import { toast } from 'react-toastify';


const PostModal = ({ show, onClose }) => {
    const companyProfilePhoto = localStorage?.getItem("companyProfilePhoto");
    const profilePhoto = localStorage?.getItem("profilePhoto");
    const companyName = localStorage?.getItem("companyName");
    const userName = localStorage?.getItem("userName");
    const token = localStorage.getItem("token");
    const { currentRole } = useRoleStore();

    const [newsFeedErrors, setNewsFeedErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newsPreviewImage, setNewsPreviewImage] = useState(null);
    const [newsData, setNewsData] = useState({
        title: "",
        description: "",
        file: null,
        role:  currentRole.toLowerCase(),
    });
  
    const handleDataChange = (e) => {
        const { name, value } = e.target;
        setNewsData({
            ...newsData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 2 * 1024 * 1024;

        if (file) {
            if (file.size > maxSizeInBytes) {
                toast.error("Image size is larger than 2MB. Please upload a smaller file.");
                e.target.value = null;
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewsPreviewImage(reader.result);
                setNewsData((newsFeed) => ({
                    ...newsFeed,
                    file: file,
                }));
            };
            reader.readAsDataURL(file);

            setTimeout(() => {
                e.target.value = null;
            }, 0);
        }
    };

    const handleNewsFeedSubmit = async () => {
        if (!newsData?.title || !newsData?.description || !newsData?.file) {
            setNewsFeedErrors(true);
            return;
        }
        try {
            setIsLoading(true);
            const formData = new FormData();
            for (const key in newsData) {
                formData.append(key, newsData[key]);
            }
            const response = await OldApi.addNewsFeed(formData, token);
            if (response?.success) {
                setNewsData((newsFeed) => ({
                    ...newsFeed,
                    title: "",
                    description: "",
                    file: null,
                    role:  currentRole.toLowerCase(),
                }));
                setNewsPreviewImage(null);
                setNewsFeedErrors(false);
                toast.success("Post added successfully");
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className='modal-post'>
            <Modal.Header >
                <Modal.Title className='w-100 d-flex justify-content-between   align-items-start px-2 py-2'>
                    <div className=' d-flex align-items-center w-100 gap-3 ps-4 pt-4'>
                        <div className="profilep-img">
                            <img src={
                                    currentRole === "investor" &&
                                        profilePhoto !== "" &&
                                        profilePhoto !== "null" &&
                                        profilePhoto
                                        ? profilePhoto
                                        : currentRole === "investor" &&
                                            (profilePhoto === "null" ||
                                                profilePhoto === "" ||
                                                profilePhoto === "undefined")
                                            ? dummyProfile
                                            : currentRole === "startup" &&
                                                (companyProfilePhoto === "null" ||
                                                    companyProfilePhoto === "undefined")
                                                ? dummyProfile
                                                : currentRole === "startup" && companyProfilePhoto
                                                    ? companyProfilePhoto
                                                    : dummyProfile
                                }
                                alt="profile"
                            />
                        </div>
                        <div className='d-flex flex-column align-items-start'>
                            <h1 className="owner-name profile-owner mb-0 d-flex align-items-center gap-2">
                                {currentRole === "investor" && userName && userName !== "null"
                                    ? userName
                                    : currentRole === "startup" && companyName && companyName !== "null"
                                        ? companyName
                                        : ""}
                            </h1>
                        </div>
                    </div>
                    <button onClick={onClose} className='cross-btn '>
                        <RxCross1
                            className="cross-icon"
                        />
                    </button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col
                        lg={12}
                        md={12}
                        xs={12}
                        className="profile-fields profile-fields-feed d-flex flex-column gap-4 px-4"
                    >
                        <div className=" form-group d-flex flex-column gap-2 mt-2">
                            <FormLabel
                                className="form-label"
                                labelText="Title"
                                required={true}
                            />
                            <InputField
                                className="form-input"
                                placeholder="Enter title"
                                type="text"
                                name="title"
                                value={newsData?.title}
                                onChange={(e) => handleDataChange(e)}
                            />
                            {newsFeedErrors && !newsData.title && (
                                <p className="error-message secondary-text mb-0">
                                    Please enter Title
                                </p>
                            )}
                        </div>
                        <div className="form-group d-flex flex-column gap-2 mt-1">
                            <FormLabel
                                className="form-label"
                                labelText="Description"
                                required={true}
                            />
                            <textarea
                                id=""
                                cols="30"
                                rows="10"
                                className="form-input about-company about-feed"
                                name="description"
                                value={newsData?.description}
                                placeholder="Enter Description"
                                onChange={(e) => handleDataChange(e)}
                            ></textarea>
                            {newsFeedErrors && !newsData.description && (
                                <p className="error-message secondary-text mb-0">
                                    Please enter Description
                                </p>
                            )}
                        </div>
                    </Col>

                    <Col lg={12} md={12} xs={12} className='px-4 mt-3'>
                        {!newsPreviewImage ? (
                            <>
                                <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                                    Add Image
                                </label>
                                <input
                                    id="file-input"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                {newsFeedErrors && !newsData.file && (
                                <p className="error-message secondary-text mb-0">
                                    Please add an image
                                </p>
                            )}
                            </>
                        ) : (
                            <>
                                <div className='position-relative mt-2'>
                                    <img
                                        src={newsPreviewImage}
                                        title="Post Cover Image"
                                        alt="Post Cover"
                                        className=" drag-img mt-0 mb-0 "
                                    />
                                    <div className="cancel-img d-flex align-items-center justify-content-center">
                                        <RxCross2
                                            className="cancel-img-icon"
                                            onClick={() => setNewsPreviewImage(null)}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Row>
                <Col lg={12} md={12} xs={12} className='pb-2'>
                    <div className="d-flex justify-content-end align-items-center gap-3 mb-2 px-4  ">
                        <button
                            className="primary-btn update-button"
                            onClick={(e) => handleNewsFeedSubmit(e)}
                        >
                            Post{" "}
                            {isLoading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default PostModal