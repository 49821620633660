import { useEffect } from 'react';
import useAuth from './modules/auth/hooks/useAuth';
import { useConfigStore } from './shared/stores/config.store.ts';
import ConfigService from './api/services/config.service';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PublicRoutes } from './routes/public.routes';
import AUTH_PATHS from './routes/auth.paths';
import RoleProvider from './context/RoleProvider';
import VerifyResultPage from './views/auth/VerifyResultPage';
import { PrivateRoutes } from './routes/private.routes';
import PrivateRoute from './routes/PrivateRoute';
import ErrorPage from './components/ErrorPage';
import RoleSetupPage from './views/profile-setup/RoleSetupPage';
import ProfileSetupPage from './views/profile-setup/ProfileSetupPage';
import PROFILE_PATHS from './routes/profile.paths';

const App = () => {
  useAuth();

  const { featureFlags, setConfig } = useConfigStore();
  useEffect(() => {
    const getFeatureFlags = async () => {
      try {
        const { featureFlags } = await ConfigService.getAllFeatureFlags();
        setConfig({ featureFlags });
      }
      catch (error) {
        console.error(error);
        // This error likely means the server is unreachable, so give generic error message
        toast.error('Error, see console for details');
      }
    };
    getFeatureFlags();
  }, [setConfig]);

  return (
    <div data-testid="app">
      <ToastContainer />
      <Router>
        <RoleProvider>
          <Routes>
            {PublicRoutes?.map((item, index) => (
              <Route key={index} path={item.path} element={<item.component />} />
            ))}
            {featureFlags.supabaseAuth && (
              <>
                <Route path={AUTH_PATHS.VERIFY_RESULT} component={VerifyResultPage} />
                <Route path={AUTH_PATHS.ERROR} component={ErrorPage} />
              </>
            )}
            {PrivateRoutes?.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<PrivateRoute component={item.component} path={item.path} />}
              />
            ))}
            <Route
              path={PROFILE_PATHS.SETUP_ROLE}
              element={<PrivateRoute path={PROFILE_PATHS.SETUP_ROLE} component={RoleSetupPage} />}
            />
            <Route
              path={PROFILE_PATHS.SETUP}
              element={<PrivateRoute path={PROFILE_PATHS.SETUP} component={ProfileSetupPage} />}
            />
            <Route path="*" element={<ErrorPage notFound={true} />} />
          </Routes>
        </RoleProvider>
      </Router>
    </div>
  );
};

export default App;
