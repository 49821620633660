import { Button, Checkbox, Form } from 'antd';
import useSelectRoleForm from '../hooks/useSelectRoleForm';
import { useState } from 'react';

const SelectRoleForm = () => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { handleSubmit, loading } = useSelectRoleForm();

  const handleChangeRoles = (roles: string[]) => {
    setSelectedRoles(roles);
  };

  return (
    <Form className="tw-space-y-10" size="large" onFinish={handleSubmit}>
      <Form.Item name="roles" rules={[{ required: true, message: 'Please select your role(s)' }]}>
        <Checkbox.Group onChange={handleChangeRoles} className="tw-flex tw-gap-10">
          <Checkbox value="investor">Investor</Checkbox>
          <Checkbox value="startup">Startup</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Button
          disabled={selectedRoles.length === 0}
          htmlType="submit"
          type="primary"
          className="tw-w-full"
          loading={loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SelectRoleForm;
