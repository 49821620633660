import React from 'react'
import Sidebar from '../components/Sidebar'
import FeedsContent from '../components/FeedsContent'
import PageTitle from '../components/PageTitle';


const Feeds = () => {
    return (
        <Sidebar>
            <PageTitle title="Home" />
            <FeedsContent />
        </Sidebar>
    )
}

export default Feeds