import { useNavigate } from 'react-router-dom';
import ProfileSetupLayout from '../../modules/profile-setup/components/ProfileSetupLayout';
import useSteps from '../../shared/hooks/useSteps';
import StartupProfileSetupForm from '../../modules/profile-setup/components/StartupProfileSetupForm';
import InvestorProfileSetupForm from '../../modules/profile-setup/components/InvestorProfileSetupForm';
import useRoleStore from '../../shared/stores/role.store';
import { useEffect } from 'react';
import PROFILE_PATHS from 'src/routes/profile.paths';
import { toast } from 'react-toastify';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import UserService from 'src/api/services/user/user.service';
import { Spin } from 'antd';
import useUserStore from 'src/shared/stores/user.store';
import { UserCurrentParams, UserCurrentResponse } from 'src/api/services/user/user.dto';
import { setLoginResponseToStorage } from 'src/modules/auth/utils/setLoginResponseToStorage';

export const PROFILE_LAYOUT_PROPS = {
  investor: {
    header: 'Investor Setup',
    title: 'Investor Setup',
    description: "The more information you provide, the more recommendations you'll receive for startups to follow.",
  },
  startup: {
    header: 'Startup Setup',
    title: 'Startup Setup',
    description: "The more information you provide, the more recommendations you'll receive for investors to follow.",
  },
};

const ProfileSetupPage = () => {
  const { roles } = useRoleStore();
  const { setUser } = useUserStore();
  const userService = new UserService();
  const { data, loading, error, refetch } = useApiFetch<UserCurrentParams, UserCurrentResponse>(
    userService.getUserCurrent,
    {},
  );
  const navigate = useNavigate();
  const totalSteps = roles.length;
  const { step, nextStep, prevStep, isLastStep } = useSteps({ totalSteps });

  const showInvestorForm = roles.includes('investor') && step === 0;
  const showStartupForm = !showInvestorForm && roles.includes('startup');

  const handleNext = () => {
    if (isLastStep) {
      refetch();
      if (error) {
        toast.error(error);
        return;
      }
      if (data) {
        // Load new profile fields into state
        // TODO Copied from login, refactor as needed
        const { firstName, lastName, email, profilePhoto, companyName, companyProfilePhoto } = data;
        setUser({
          firstName,
          lastName,
          email,
          profilePhoto,
          companyName,
          companyProfilePhoto,
        });
        setLoginResponseToStorage(data);
        navigate('/');
      }
    }
    else {
      nextStep();
    }
  };

  useEffect(() => {
    if (!roles.length) {
      navigate(PROFILE_PATHS.SETUP_ROLE);
      toast.error('Please select a role to continue.');
      return;
    }
  }, [roles, navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Spin spinning={loading}>
      <ProfileSetupLayout
        step={step}
        totalSteps={totalSteps}
        {...(showInvestorForm ? PROFILE_LAYOUT_PROPS.investor : PROFILE_LAYOUT_PROPS.startup)}
      >
        {showInvestorForm && <InvestorProfileSetupForm onNext={handleNext} />}
        {showStartupForm && (
          <StartupProfileSetupForm onNext={handleNext} onPrev={totalSteps > 1 ? prevStep : undefined} />
        )}
      </ProfileSetupLayout>
    </Spin>
  );
};

export default ProfileSetupPage;
