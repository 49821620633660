import StartupService from '../../../api/services/profiles/startup.service';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  onCompleted: () => void;
}

export const useUpdateStartupProfilePrivacy = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateStartupProfilePrivacy = async ({ isPrivateProfile }: { isPrivateProfile: boolean }) => {
    try {
      setLoading(true);

      const response = await StartupService.updateStartupProfile({
        isPrivateProfile,
      });

      if (response?.success) {
        onCompleted();
        toast.success(isPrivateProfile ? 'Profile privacy set to private' : 'Profile privacy set to public');
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateStartupProfilePrivacy,
    loading,
  };
};
