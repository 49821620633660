import { Avatar, Button, Card, Divider, Dropdown, Flex, Space, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import StartupHeaderEditModal from './StartupHeaderEditModal';
import { useRef } from 'react';
import { handleCopyProfileUrl } from '../utils/handleCopyProfileUrl';
import MakeProfilePrivateModal from './MakeProfilePrivateModal';
import { useUpdateStartupProfilePrivacy } from '../hooks/useUpdateStartupProfilePrivacy';
import { generateProfilePDF } from '../utils/generateProfilePdf';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import { ReactComponent as CompanyLogoDefault } from '../../../assets/svgs/company-logo-default.svg';
import { useUpdateStartupProfile } from '../hooks/useUpdateStartupProfile';
import UploadCover from '../../../shared/components/UploadCover';
import { useFollowProfile } from '../hooks/useFollowProfile';
import { useUnfollowProfile } from '../hooks/useUnfollowProfile';
import { FaAngleDown } from 'react-icons/fa6';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { b64toBlob } from '../../../shared/utils/files';

interface Props {
  canUpdate: boolean;
  profilePhoto?: string;
  coverPhoto?: string;
  companyName?: string;
  handle?: string;
  country?: string;
  elevatorPitch?: string;
  numberOfFollowers?: number;
  isPrivateProfile?: boolean;
  isFollowing?: boolean;
  id?: string;
  refetch: () => void;
}

const HeadingSection = ({
  canUpdate,
  profilePhoto,
  coverPhoto,
  companyName,
  handle,
  country,
  elevatorPitch,
  numberOfFollowers,
  isPrivateProfile,
  isFollowing,
  id,
  refetch,
}: Props) => {
  const { open: editProfileModalOpen, onClose: onCloseEditModal, onOpen: onOpenEditModal } = useToggle();
  const { open: privacyModalOpen, onClose: onClosePrivacyModal, onOpen: onOpenPrivacyModal } = useToggle();
  const { handleSubmit } = useUpdateStartupProfile({ onCompleted: refetch });
  const formInitialValuesRef = useRef({});

  const onStartupProfilePrivacyUpdated = () => {
    refetch();
    onClosePrivacyModal();
  };
  const { handleUpdateStartupProfilePrivacy } = useUpdateStartupProfilePrivacy({
    onCompleted: onStartupProfilePrivacyUpdated,
  });

  const { handleFollowProfile } = useFollowProfile({ onCompleted: refetch });
  const { handleUnfollowProfile } = useUnfollowProfile({ onCompleted: refetch });

  const handleChangePrivacy = () => {
    if (isPrivateProfile) {
      handleUpdateStartupProfilePrivacy({ isPrivateProfile: false });
    }
    else {
      onOpenPrivacyModal();
    }
  };

  const handleChangeProfilePicture = (data: string) => {
    const formData = new FormData();
    formData.append('profilePhoto', b64toBlob(data));
    handleSubmit(formData);
  };

  const handleChangeCoverPhoto = (data: string) => {
    const formData = new FormData();
    formData.append('coverPhoto', b64toBlob(data));
    handleSubmit(formData);
  };

  const items = [
    {
      label: (
        <Button size="small" type="text" onClick={() => handleCopyProfileUrl(handle)}>
          Copy profile link
        </Button>
      ),
      key: 'copy',
    },
    {
      label: (
        <Button size="small" type="text" onClick={() => generateProfilePDF({ companyName })}>
          Download page as pdf
        </Button>
      ),
      key: 'download-pdf',
    },
    {
      label: (
        <Button size="small" type="text" onClick={handleChangePrivacy}>
          {isPrivateProfile ? 'Make profile public' : 'Make profile private'}
        </Button>
      ),
      key: 'set-private',
    },
    {
      label: (
        <Button
          size="small"
          type="text"
          href={`/startup/${handle}`}
          target="_blank"
        >
          View as visitor
        </Button>
      ),
      key: 'view-as-visitor',
    },
  ];

  const handleOpenEditModal = () => {
    onOpenEditModal();
    formInitialValuesRef.current = {
      companyName,
      country,
      handle,
      companyProfile: elevatorPitch,
    };
  };

  const handleCompleted = () => {
    refetch();
    onCloseEditModal();
  };

  const handleFollow = () => {
    handleFollowProfile({
      followingId: id,
      type: 'startup',
      followerRole: 'startup',
    });
  };

  const handleUnfollow = () => {
    handleUnfollowProfile({
      followingId: id,
      type: 'startup',
      followerRole: 'startup',
    });
  };

  return (
    <>
      <StartupHeaderEditModal
        open={editProfileModalOpen}
        onClose={onCloseEditModal}
        initialValues={formInitialValuesRef.current}
        onCompleted={handleCompleted}
      />

      <MakeProfilePrivateModal
        open={privacyModalOpen}
        onClose={onClosePrivacyModal}
        onCompleted={onStartupProfilePrivacyUpdated}
      />

      <Card
        cover={
          canUpdate ? (
            <UploadCover width={996} height={360} onChange={handleChangeCoverPhoto} file={coverPhoto} />
          ) : (
            <>
              {coverPhoto ? (
                <img
                  alt="example"
                  src={coverPhoto}
                  className="tw-h-[360px] tw-bg-contain tw-bg-center tw-bg-no-repeat"
                />
              ) : (
                <div className="tw-bg-background-black tw-text-center">
                  <Logo />
                </div>
              )}
            </>
          )
        }
      >
        <div className="tw-space-y-3">
          <Flex justify="space-between" gap={8} align="center">
            <Space size={12}>
              <div className="tw-absolute tw-left-6 tw-top-[312px]">
                {canUpdate ? (
                  <UploadAvatar
                    file={profilePhoto}
                    size={153}
                    defaultImage={<CompanyLogoDefault />}
                    onChange={handleChangeProfilePicture}
                  />
                ) : (
                  <Avatar
                    src={profilePhoto || <CompanyLogoDefault />}
                    size={153}
                    className="tw-border-4 tw-border-text-white"
                  />
                )}
              </div>
              <div className="tw-space-y-1 tw-pl-40">
                <Typography.Title level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                  {companyName}
                </Typography.Title>
                <Typography.Text className="tw-block tw-text-xs tw-text-text-body">{country}</Typography.Text>
                <Button type="link" className="tw-h-8 tw-p-0 tw-text-primary-500" size="small">
                  {numberOfFollowers} {numberOfFollowers === 1 ? 'follower' : 'followers'}
                </Button>
              </div>
            </Space>

            <Flex gap={8} align="center">
              {canUpdate ? (
                <>
                  <Dropdown menu={{ items }} placement="bottomLeft">
                    <Button type="default" size="small">
                      More
                    </Button>
                  </Dropdown>

                  <Tooltip title="Edit profile">
                    <Button type="default" size="small" onClick={handleOpenEditModal}>
                      <AiOutlineEdit size={20} />
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  {!isFollowing ? (
                    <Button type="primary" size="small" onClick={handleFollow}>
                      Follow
                    </Button>
                  ) : (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <Button type="text" size="small">
                                Unfollow
                              </Button>
                            ),
                            key: 'unfollow',
                            onClick: handleUnfollow,
                          },
                        ],
                      }}
                      placement="bottomRight"
                    >
                      <Button type="default" size="small" icon={<FaAngleDown size={18} />} iconPosition="end">
                        Following
                      </Button>
                    </Dropdown>
                  )}

                  <Button type="default" size="small" onClick={() => generateProfilePDF({ companyName })}>
                    Download page
                  </Button>
                  <Button type="default" size="small" onClick={() => handleCopyProfileUrl(handle)}>
                    Copy profile link
                  </Button>
                </>
              )}
            </Flex>
          </Flex>

          {elevatorPitch && (
            <>
              <Divider />

              <Typography.Paragraph className="tw-text-md tw-font-medium tw-text-text-body">
                {elevatorPitch}
              </Typography.Paragraph>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default HeadingSection;
