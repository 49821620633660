import { jwtDecode } from 'jwt-decode';
import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'src/modules/auth/stores/auth.store';
import AUTH_PATHS from 'src/routes/auth.paths';
import useRoleStore from 'src/shared/stores/role.store';
import useUserStore from 'src/shared/stores/user.store';

export const isTokenExpired = (expiration: number) => {
  if (!expiration) {
    return false;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp >= expiration;
};

interface RoleContextProps {
  activeMenuItem?: string;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string>>;
}

interface RoleStateProps {
  children: ReactNode;
}

export const RoleContext = createContext<RoleContextProps>({
  setActiveMenuItem: () => {},
});

/**
 * Handles current role state and other things...
 *
 * TODO This provider is from the MVP, it should be removed
 */
const RoleProvider: React.FC<RoleStateProps> = props => {
  const { setRoles, setCurrentRole } = useRoleStore();
  const { setUser } = useUserStore();
  const { login } = useAuthStore();

  // Manages sidebar menu items
  // TODO Saving active menu item between sessions provides little value, remove
  const activeTab = localStorage.getItem('activeMenuItem');
  // TODO Handle within sidebar component
  const [activeMenuItem, setActiveMenuItem] = useState<string>(activeTab ? activeTab : 'home');

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    localStorage.clear();
    navigate(AUTH_PATHS.LOGIN);
  }, [navigate]);

  // This function is from the MVP, replace with library as needed
  function parseJwt(token: string | null) {
    if (!token) {
      return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    if (window.location.pathname === '/home') {
      localStorage?.setItem('activeTab', 'home');
      setActiveMenuItem('home');
    }

    const params = new URLSearchParams(location.search);

    if (params && params.size > 0) {
      const googleToken = params.get('token');
      params.delete('token');
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      navigate(newUrl);

      if (googleToken) {
        localStorage.setItem('token', googleToken);
      }

      const token = localStorage.getItem('token');
      if (!token) {
        handleLogout();
        return;
      }
      const jwtPayload = jwtDecode(token || '');
      if (isTokenExpired(jwtPayload.exp ?? 0)) {
        handleLogout();
        return;
      }
      const decoded = parseJwt(token);
      if (decoded.user?.role) {
        login(token);
        setRoles(decoded.user.role);
        setCurrentRole(decoded.user.role.includes('investor') ? 'investor' : 'startup');
        setUser({
          firstName: decoded.user.firstName,
          lastName: decoded.user.lastName,
          email: decoded.user.email,
          // TODO Social login doesn't provide these, need to set current user from api
          // profilePhoto: decoded.user.profilePhoto,
          // companyName: decoded.user.companyName,
          // companyProfilePhoto: decoded.user.companyProfilePhoto,
        });
      }
    }
  }, [handleLogout, location.pathname, location.search, login, navigate, setCurrentRole, setRoles, setUser]);

  return (
    <RoleContext.Provider
      value={{
        activeMenuItem: activeMenuItem,
        setActiveMenuItem: setActiveMenuItem,
      }}
    >
      {props.children}
    </RoleContext.Provider>
  );
};
export default RoleProvider;
