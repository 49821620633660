import { UserCurrentResponse } from '../../../api/services/user/user.dto';
import { OldLoginResponse } from '../../../api/services/auth/auth.dto';

export const setLoginResponseToStorage = (data: (OldLoginResponse | UserCurrentResponse)['data']) => {
  localStorage.setItem('userName', data.firstName ? data.firstName + ' ' + data.lastName : '');
  localStorage.setItem('profilePhoto', data.profilePhoto ?? '');
  localStorage.setItem('userId', data.userId);
  localStorage.setItem('companyName', data.companyName ?? '');
  localStorage.setItem('companyProfilePhoto', data.companyProfilePhoto ?? '');
};
