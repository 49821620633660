import SelectRoleForm from '../../modules/profile-setup/components/SelectRoleForm';
import ProfileSetupLayout from '../../modules/profile-setup/components/ProfileSetupLayout';

const RoleSetupPage = () => {
  return (
    <ProfileSetupLayout
      header="Role Setup"
      title="Select Role"
      description="To ensure you have the best experience on our platform, please select your role below, 
        to set up your profile and start using features tailored to your needs."
    >
      <SelectRoleForm />
    </ProfileSetupLayout>
  );
};

export default RoleSetupPage;
