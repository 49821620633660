import { BaseResponse } from './../../api/common';
import { useState, useEffect, useRef, useCallback } from 'react';

type FetchMethod<Params, Response> = (params: Params) => Promise<Response>;

interface FetchResult<Response> {
  data: Response | null;
  loading: boolean;
  error: string | null;
  status: number | null;
  refetch: () => void;
}

/**
 * A hook that fetches data from API using given fetch method and params.
 * It handles loading and error states and returns a refetch function.
 *
 * @param fetchMethod A function that fetches data from API.
 * @param params Parameters for the fetch method.
 * @returns An object containing the fetched data, loading state, error message,
 * and a refetch function.
 */
const useApiFetch = <Params, Response extends BaseResponse & { data: unknown }>(
  fetchMethod: FetchMethod<Params, Response>,
  params: Params,
): FetchResult<Response['data'] | null> => {
  const [data, setData] = useState<Response | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const prevParamsRef = useRef<Params | null>(null);

  // Function to fetch data
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchMethod(params);

      if (response.success) {
        setData(response.data as Response);
      }
      else {
        setError(response.message);
      }
    }
    catch (error: any) {
      // Request errors are logged in requests.ts, check the console
      setError(error.message || 'An unknown error occurred');
      setStatus(error.status);
    }
    finally {
      setLoading(false);
    }
  }, [fetchMethod, params]);

  // Refetch function
  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (JSON.stringify(prevParamsRef.current) !== JSON.stringify(params)) {
      fetchData();
      prevParamsRef.current = params;
    }
  }, [params, fetchData]);

  return { data, loading, error, status, refetch };
};

export default useApiFetch;
