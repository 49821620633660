import { Button, Col, Flex, Form, Input, Row } from 'antd';
import CountrySelector from './CountrySelect';
import InterestCategorySelect from './IndustrySelect';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import { useStartupSetupForm } from '../hooks/useStartupSetupForm';
import useUserStore from '../../../shared/stores/user.store';
import { ReactComponent as CompanyLogoDefault } from '../../../assets/svgs/company-logo-default.svg';

interface Props {
  onNext: () => void;
  onPrev?: () => void;
}

const StartupProfileSetupForm = ({ onNext, onPrev }: Props) => {
  const [form] = Form.useForm();
  const { email } = useUserStore();
  const { handleSubmit, loading } = useStartupSetupForm({ onCompleted: onNext });

  return (
    <Form form={form} layout="vertical" size="large" className="tw-space-y-10" onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col span={24} className="tw-flex tw-justify-center">
          <Form.Item name="profilePhoto" className='tw-w-fit tw-flex-shrink-0'>
            <UploadAvatar defaultImage={<CompanyLogoDefault />} />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item
            label="Company name"
            name="companyName"
            rules={[{ required: true, message: 'Please enter your company name' }]}
          >
            <Input placeholder="Enter company name" />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item
            label="Email"
            name="companyEmail"
            initialValue={email}
            rules={[
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="Enter company email" />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item label="Founder(s)" name="founder">
            <Input placeholder="Enter Company Founder(s)" />
          </Form.Item>
        </Col>

        <Col md={24} xl={12} xs={24}>
          <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Please select your country' }]}>
            <CountrySelector showSearch />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="interestCategories"
            label="Industry"
          >
            <InterestCategorySelect placeholder="Select industry" mode="multiple" maxTagCount={2} allowClear />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button htmlType="submit" type="primary" className="tw-w-full" loading={loading}>
          Save
        </Button>
      </Form.Item>

      {!!onPrev && (
        <Flex align="center" justify="center" className="tw-w-full">
          <Button type="link" onClick={onPrev}>
            Back
          </Button>
        </Flex>
      )}
    </Form>
  );
};

export default StartupProfileSetupForm;
