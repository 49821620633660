import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDots } from "react-icons/bs";
import { RiEdit2Fill } from "react-icons/ri";
import EditModal from "./EditModal";
import { MdDelete } from "react-icons/md";
import OldApi from "../api-old/api";
import { toast } from "react-toastify";

const InvestmentDropdown = ({ rowData, getInvestment }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const token = localStorage.getItem("token");
  const handleToggleModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleDelete = async (id) => {
    try {
      const response = await OldApi.deleteInvestment(id, token);
      if (response?.success) {
        toast.success("Investment Deleted Succcessfully");
        getInvestment(1, 5);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      toast.error(e?.data?.message);
    } finally {
    }
  };

  return (
    <Dropdown variant="success" id="dropdown-basic">
      <Dropdown.Toggle className="table-dropdown">
        <BsThreeDots className="three-dot" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="investment-menu">
        <Dropdown.Item onClick={handleToggleModal}>
          <RiEdit2Fill className="del-icon" />
          Edit
        </Dropdown.Item>
        <EditModal
          show={showEditModal}
          onClose={handleToggleModal}
          data={rowData}
          getInvestment={getInvestment}
        />
        <Dropdown.Item onClick={() => handleDelete(rowData?.id)}>
          <MdDelete className="del-icon" />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InvestmentDropdown;
