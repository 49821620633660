  /**
   * Converts a base64 encoded data URI to a blob.
   *
   * @param {string} dataURI The base64 encoded data URI.
   * @returns {Blob} The blob.
   */
  export function b64toBlob(dataURI: string): Blob {
    
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
  