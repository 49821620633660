import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Spinner } from "react-bootstrap";
import FormLabel from "../components/FormLabel";
import "react-phone-number-input/style.css";
import { HiOutlineArrowRight } from "react-icons/hi2";
import OldApi from "../api-old/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../utils";
import PasswordInput from "../components/PasswordInput";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import InputField from "../components/InputField";
import PageTitle from "../components/PageTitle";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(false);
  const [sendOTPLoader, setSendOTPLoader] = useState(false);

  const [errors, setErrors] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [OTP, setOTP] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [stepper, setStepper] = useState(0);

  const [formValues, setFormValues] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const errorMessage =
      name === "password"
        ? !validatePassword(value)
          ? "Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters."
          : ""
        : "";
    setErrors({ ...errors, [name]: errorMessage });
    if (
      name === "password" ||
      (name === "confirmPassword" && formValues?.currentPassword)
    ) {
      setErrors({ ...errors, currentPassword: "" });
    }
  };

  const handleOTPChange = (e) => {
    const { value } = e.target;
    setOTP(value);
  };

  const handleTogglePassword = (event, stateToUpdate) => {
    event.preventDefault();
    stateToUpdate((prevState) => !prevState);
  };

  const sendOTPCode = async (e) => {
    setSendOTPLoader(true);
    e.preventDefault();
    try {
      const response = await OldApi.sendOTP(token);
      if (response.success) {
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setSendOTPLoader(false);
    }
  };

  const verifyOTPCode = async (e) => {
    e.preventDefault();
    try {
      const response = await OldApi.verifyOTP(token, { OTP });
      if (response.success) {
        setStepper(1);
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword, currentPassword } = formValues;

    switch (true) {
      case !currentPassword.trim():
        setErrors({ currentPassword: "Please enter your current password" });
        return;
      case !password.trim():
        setErrors({ password: "Please enter new password" });
        return;
      case !validatePassword(password):
        setErrors({
          password:
            "Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.",
        });
        return;
      case !confirmPassword.trim():
        setErrors({ confirmPassword: "Please confirm your password" });
        return;
      case password !== confirmPassword:
        setErrors({ confirmPassword: "Passwords do not match" });
        return;
      default:
        break;
    }
    try {
      setLoading(true);

      const response = await OldApi.changePassword(formValues, token);
      if (response.success) {
        setFormValues({
          currentPassword: "",
          password: "",
          confirmPassword: "",
        });
        setErrors({
          currentPassword: "",
          password: "",
          confirmPassword: "",
        });
        toast.success(response.message);
      }
    } catch (error) {
      if (error?.data?.message === "Password is not correct") {
        setErrors({ currentPassword: error?.data?.message });
      } else {
        toast.error(error?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOTPStatus = async () => {
    try {
      const response = await OldApi.OTPStatus(token);
      if (response.success) {
        setStepper(
          user?.provider && response?.data?.password
            ? 0
            : !user?.provider && response?.data?.password
              ? 0
              : 2
        );
        setSpinner(false);
      }
    } catch (error) {
      setSpinner(false);
      toast.error(error?.data?.message);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formValues;

    switch (true) {
      case !password.trim():
        setErrors({ password: "Please enter your password" });
        return;
      case !validatePassword(password):
        setErrors({
          password:
            "Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.",
        });
        return;
      case !confirmPassword.trim():
        setErrors({ confirmPassword: "Please confirm your password" });
        return;
      case password !== confirmPassword:
        setErrors({ confirmPassword: "Passwords do not match" });
        return;
      default:
        break;
    }
    try {
      setLoading(true);

      const response = await OldApi.updatePassword(
        { password, confirmPassword, type: "socialLogin" },
        token
      );
      if (response.success) {
        setFormValues({
          currentPassword: "",
          password: "",
          confirmPassword: "",
        });
        setErrors({
          currentPassword: "",
          password: "",
          confirmPassword: "",
        });
        toast.success("Password set successfully");
        setStepper(0);
      }
    } catch (error) {
      toast.error(error?.data?.message || "Failed to update password");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (user?.provider) {
      getOTPStatus();
    } else {
      setSpinner(false);
    }
  }, []);

  return (
    <Sidebar>
      <PageTitle title="Update Password" />
      <Container fluid className="main-content dashboard-main">
        <div className="dash-content ">
          {spinner ? (
            <div className="text-center visual-loader">
              <Spinner
                animation="border"
                role="status"
                style={{ width: "2rem", height: "2rem", color: "#ff4814" }}
              >
                <span className="visually-hidden ">Loading...</span>
              </Spinner>
              <p className="mt-2 joined-headings">Loading...</p>
            </div>
          ) : (
            <Col lg={12} md={12} sm={12} className="user-settings">
              <h1 className="trending-heading mb-0">
                Update Password
                <span className="secondary-text slash-trending">_</span>
              </h1>
              {stepper == 2 ? (
                <Form
                  className="mt-5 login-form update-form "
                  onSubmit={(e) => verifyOTPCode(e)}
                >
                  <Row>
                    <Col lg={6} md={12} xs={12}>
                      <div className="form-group form-input-label d-flex flex-column gap-2 ">
                        <InputField
                          className="form-input"
                          placeholder="Enter OTP"
                          type="text"
                          name="otp"
                          value={OTP}
                          required
                          onChange={handleOTPChange}
                        />
                      </div>
                    </Col>
                    <Col
                      lg={6}
                      md={12}
                      xs={12}
                      className="d-flex align-items-center mt-1"
                    >
                      <button
                        type="submit"
                        className="otp-button primary-btn d-flex gap-2 justify-content-center align-items-center "
                      >
                        Verify OTP
                      </button>
                    </Col>
                  </Row>
                  <p className="otp-note">
                    To ensure security, we'll send you a one-time password (OTP)
                    for verification.
                    <a
                      href="/"
                      className="secondary-text"
                      onClick={(e) => {
                        sendOTPCode(e);
                      }}
                    >
                      &nbsp;Send OTP{" "}
                      {sendOTPLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </a>
                  </p>
                </Form>
              ) : (
                <Form
                  className="mt-5 login-form update-form "
                  onSubmit={(e) =>
                    stepper === 1 ? handleUpdatePassword(e) : handleSubmit(e)
                  }
                >
                  {stepper === 0 ? (
                    <Row className="profile-setup">
                      <Col lg={6} md={12} xs={12}>
                        <div className="form-group form-input-label d-flex flex-column gap-2 mb-4">
                          <FormLabel
                            className="form-label"
                            labelText="Current Password"
                            required={true}
                          />
                          <PasswordInput
                            className="form-input"
                            type="password"
                            placeholder="Enter Current Password"
                            showPassword={showOldPassword}
                            handleTogglePassword={(e) =>
                              handleTogglePassword(e, setShowOldPassword)
                            }
                            name="currentPassword"
                            value={formValues?.currentPassword}
                            onChange={handleChange}
                          />
                          {errors.currentPassword && (
                            <p className="error-message secondary-text mb-0">
                              {errors.currentPassword}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col lg={6} md={12} xs={12}>
                      <div className="form-group form-input-label d-flex flex-column gap-2 mb-3">
                        <FormLabel
                          className="form-label"
                          labelText="New Password"
                          required={true}
                        />
                        <PasswordInput
                          className="form-input"
                          type="password"
                          placeholder="New password"
                          showPassword={showPassword}
                          handleTogglePassword={(e) =>
                            handleTogglePassword(e, setShowPassword)
                          }
                          name="password"
                          value={formValues?.password}
                          onChange={handleChange}
                        />
                        {errors.password && (
                          <p className="error-message secondary-text mb-0">
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} md={12} xs={12}>
                      <div className="form-group form-input-label d-flex flex-column gap-2 mb-3">
                        <FormLabel
                          className="form-label"
                          labelText="Confirm Password"
                          required={true}
                        />
                        <PasswordInput
                          className="form-input"
                          type="password"
                          placeholder="Confirm password"
                          showPassword={confirmPassword}
                          handleTogglePassword={(e) =>
                            handleTogglePassword(e, setConfirmPassword)
                          }
                          name="confirmPassword"
                          value={formValues?.confirmPassword}
                          onChange={handleChange}
                        />
                        {errors.confirmPassword && (
                          <p className="error-message secondary-text mb-0">
                            {errors.confirmPassword}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="update-pwd d-flex gap-3 justify-content-end align-items-center">
                    <button
                      className=" view-button mt-0 update-button"
                      type="button"
                      onClick={() => navigate("/home")}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={
                        " user-settings-btn  update-button text-white d-flex gap-2 justify-content-center align-items-center"
                      }
                    >
                      Update
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <HiOutlineArrowRight className="right-arrow" />
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Col>
          )}
        </div>
        <Footer className="simple-container" />
      </Container>
    </Sidebar>
  );
};

export default UpdatePassword;
