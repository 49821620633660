import { Button, Card, Flex, Tag, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import Description from '../../../shared/components/Description';
import Paragraph from '../../../shared/components/Paragraph';
import StartupIntroEditModal from './StartupIntroEditModal';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';

interface Props {
  aboutCompany: string;
  interestCategories: string[];
  canUpdate: boolean;
  refetch: () => void;
}

/**
 * IntroductionSection component renders the introduction section
 */
const IntroSection = ({ aboutCompany, interestCategories, canUpdate, refetch }: Props) => {
  const { open, onClose, onOpen } = useToggle();
  const formInitialValuesRef = useRef({});

  const handleOpenEditModal = () => {
    onOpen();
    formInitialValuesRef.current = {
      aboutCompany,
      interestCategories,
    };
  };

  const handleCompleted = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <StartupIntroEditModal
        open={open}
        initialValues={formInitialValuesRef.current}
        onClose={onClose}
        onCompleted={handleCompleted}
      />
      <Card>
        <Flex justify="space-between" align="center">
          <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary">
            Introduction
          </Typography.Title>
          {canUpdate && (
            <Tooltip title="Edit profile">
              <Button type="default" size="small" onClick={handleOpenEditModal}>
                <AiOutlineEdit size={20} />
              </Button>
            </Tooltip>
          )}
        </Flex>

        <div className="tw-space-y-4">
          <Description label="About Us">
            {aboutCompany ? <Paragraph content={aboutCompany} rows={3} /> : null}
          </Description>

          <Description label="Industry">
            {interestCategories?.length > 0 ? (
              <Flex gap={8} align="center" wrap>
                {interestCategories?.map((item: string) => (
                  <Tag className="tw-m-0" key={item}>
                    {item}
                  </Tag>
                ))}
              </Flex>
            ) : null}
          </Description>
        </div>
      </Card>
    </>
  );
};

export default IntroSection;
