// TODO Organize by module
const updatePassword = '/:token/update-password';
const resetPassword = '/reset-password';
const sendEmail = '/send-email';
const verifyEmail = '/:token/verify-email';

const userSettings = '/user-settings';

const followers = '/followers';

const home = '/home';
const postView = '/post/:id';

const startups = '/startups';
const investors = '/investors';

const chat = '/chat';

export {
  resetPassword,
  verifyEmail,
  updatePassword,
  home,
  sendEmail,
  chat,
  investors,
  startups,
  followers,
  userSettings,
  postView,
};
