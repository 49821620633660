import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { VALID_IMAGE_TYPES } from "../constants/files";

interface Props {
  onChange?: (url: string) => void;
  maxSizeInMB: number
  file?: string | null;
}

const useUpload = ({ file, maxSizeInMB, onChange }: Props) => {
  const [fileData, setFileData] = useState<string | null | undefined>(file);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFileData(file as string);
  }, [file]);

  const handleBeforeUpload = (file: RcFile) => {
    const isValidImageType = VALID_IMAGE_TYPES.includes(file.type);
    if (!isValidImageType) {
      toast.error('You can only upload image file');
    }

    const isValidFileSize = file.size / 1024 / 1024 < maxSizeInMB;
    if (!isValidFileSize) {
      toast.error(`Image must be smaller than ${maxSizeInMB}MB`);
    }

    return isValidImageType && isValidFileSize;
  };

  const handleUpload = async ({ file }: { file: string | Blob | RcFile | File }) => {
    try {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file as Blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        onChange?.(base64String);
        setFileData(base64String);
      };
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    fileData,
    loading,
    handleUpload,
    handleBeforeUpload
  }
}

export default useUpload