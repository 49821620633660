import { Form, Input, Button, Checkbox, Divider, Typography, Flex } from 'antd';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';
import { useSignUpForm } from '../hooks/useSignUpForm';
import { RegisterDto } from '../../../api/services/auth/auth.dto';
import CaptchaCheckbox from './CaptchaCheckbox';

const SignUpForm = () => {
  const { handleSignUp, loading, hasAgreePolicy, handleChangePolicyCheckbox } = useSignUpForm();

  return (
    <Form size="large" layout="vertical" onFinish={handleSignUp}>
      <SocialLinks />

      <Divider plain>
        <Typography.Paragraph className="tw-text-sm tw-text-text-body tw-mb-0">
          Or continue with email
        </Typography.Paragraph>
      </Divider>

      <Form.Item<RegisterDto>
        label="Email Address"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email address' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <Input placeholder="Enter your email address" />
      </Form.Item>

      <Form.Item<RegisterDto>
        label="Password"
        name="password"
        rules={[
          { required: true },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.@*\s).{8,}$/,
            message: 'Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.',
          },
        ]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <div className="formlabel-input d-flex align-items-center gap-2 mt-4 ">
        <Checkbox checked={hasAgreePolicy} onChange={handleChangePolicyCheckbox} />
        <p className="checkbox-desc mb-0">
          I agree to Angelflow's Website{' '}
          <Link to="https://www.angelflow.vc/terms-of-use" className="text-decoration-none" target="_blank">
            <b>Terms of Use</b>
          </Link>{' '}
          and
          <Link to="https://www.angelflow.vc/privacy-policy" className="text-decoration-none" target="_blank">
            {' '}
            <b>Privacy Policy</b>
          </Link>
        </p>
      </div>

      <Flex className="tw-mt-6" vertical align="center" justify="center">
        <Form.Item<RegisterDto>
          name="captcha"
          rules={[{ required: true, message: 'Please verify that you are not a robot' }]}
        >
          <CaptchaCheckbox />
        </Form.Item>
      </Flex>

      <Form.Item>
        <Button
          type="primary"
          disabled={!hasAgreePolicy}
          loading={loading}
          className="tw-w-full"
          htmlType="submit"
          size="large"
        >
          Create Account
        </Button>
      </Form.Item>

      <p className="go-signin ">
        Already on Angelflow?
        <Link to="/" className="secondary-text">
          &nbsp;Sign in
        </Link>
      </p>
    </Form>
  );
};

export default SignUpForm;
