import { Button, Image, ImageProps, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/modal/style';
import 'antd/lib/slider/style';
import { ReactNode } from 'react';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { ReactComponent as Logo } from '../../assets/svgs/angel-logo-white.svg';
import { User } from '../../utils/images';
import useUpload from '../hooks/useUpload';

interface Props {
  onChange?: (url: string) => void;
  file?: string | null;
  maxSizeInMB?: number;
  defaultImage?: string | ReactNode;
}

function UploadCover({
  onChange,
  file,
  width = 100,
  height = 100,
  maxSizeInMB = 5,
  defaultImage = User,
  ...rest
}: Props & Omit<ImageProps, 'onChange'>) {
  const { fileData, loading, handleUpload, handleBeforeUpload } = useUpload({ file, maxSizeInMB, onChange });

  return (
    <Spin spinning={loading}>
      {fileData ? (
        <Image preview={false} src={fileData} height={height} alt="image" {...rest} />
      ) : (
        <div className="tw-bg-background-black tw-text-center" style={{ height }}>
          <Logo />
        </div>
      )}
      <ImgCrop showGrid aspect={+width / (+height || 1)} minZoom={0.5}>
        <Upload
          accept="image/*"
          customRequest={handleUpload}
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          maxCount={1}
          className="tw-cursor-pointer"
          progress={{
            strokeWidth: 4,
            showInfo: false,
          }}
        >
          <Button type="default" size="small" className="tw-absolute tw-right-5 tw-top-5 tw-border-none">
            <MdOutlinePhotoCamera />
            {fileData ? 'Edit cover photo' : 'Upload cover photo'}
          </Button>
        </Upload>
      </ImgCrop>
    </Spin>
  );
}

export default UploadCover;
