
// TODO Don't save token in local/session storage, use Supabase session/cookie
export const setToken = (token: string, isRememberMe?: boolean) => {
  // TODO Old code relies on token in local storage; always store it until old code is removed
  if (true || isRememberMe) {
    localStorage.setItem('token', token);
  }
  // else {
  //   sessionStorage.setItem('token', token);
  // }
};

export const getToken = () => {
  return localStorage.getItem('token');
  // ?? sessionStorage.getItem('token');
};
